
<template>
  <div class="shop-wrapper disFlex">
    <div>
      <div class="phone-previvew mr20 bor1d pr br4">
        <img
          src="https://j.weizan.cn/livecontent/img/Addquestionnaire/header.jpg"
          alt
          class="w100p phone-header"
        />
        <div class="set-preview pr" style="height: auto;padding: 0;">
          <img
            style="width: 375px;"
            src="https://j.weizan.cn/zhibo/livecontent/homelive/img/store-image.png"
          />
          <div class="pa top0 w100p left0 zindex1">
            <img
              v-if="storeInfo.logo"
              :src="storeInfo.logo"
              style="height: 210px;object-fit:cover;"
              class="w100p"
            />
            <marquee
              behavior="behavior"
              class="w100p h30 lh30"
              loop="loop"
              style="background-color: #fceac2;color: #794315;"
              scrolldelay="100"
              scrollamount="5"
            >{{ storeInfo.notice }}</marquee>
          </div>
        </div>
      </div>
    </div>
    <div class="setting-box">
      <div class="table-title">店面信息设置</div>

      <el-form class="mt20" :model="storeInfo" label-width="130px">
        <el-form-item label="头图" required>
          <div>
            <label for="brand-logo" class="el-upload el-upload--picture-card">
              <input
                type="file"
                ref="brandlogo"
                hidden
                id="brand-logo"
                data-type="brandLogo"
                name="upload"
                accept="image/*"
                @change="uploadImagenew"
              />
              <img v-if="storeInfo.logo" :src="storeInfo.logo" style="width:142px;height:143px;" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </label>
          </div>
          <span class="ml10 form_tips">建议宽高比16:9，支持JPG，PNG，JPEG格式</span>
        </el-form-item>
        <el-form-item label="滚动公告" required>
          <el-input
            placeholder="请输入滚动公告 "
            v-model="storeInfo.notice"
            maxlength="50"
            type="textarea"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="店面信息" required>
          <el-radio-group v-model="storeInfo.showType">
            <el-radio label="all">默认展开所有店面信息</el-radio>
            <el-radio label="one">只展开第一个店面信息，其余店面信息需手动展开查看</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="品牌商排序" class="province" required>
          <p>按照品牌商排序值，全局显示所有品牌商</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPageShop">保 存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "ShopSetting", // 店铺配置
  components: {},
  props: {
    activityID: String
  },
  computed: {},
  watch: {},
  data() {
    return {
      upload: null, // 上传对象
      baseInfo: {
        id: "",
        actName: "", // 活动名称
        timeRange: [],
        actStartTime: "", // 活动开始时间
        actEndTime: "", // 活动结束时间
        // 活动区域列表
        areaList: [
          {
            city: "",
            county: "",
            province: ""
          }
        ],
        poster: "", // 活动海报图url
        complainMobile: "", // 投诉电话
        liveStartTime: "", // 直播开始时间
        liveUrl: "" // 直播地址
      },
      storeInfo: {
        actId: this.activityID,
        logo: "",
        notice: "",
        showType: "all"
      },
      radio: ""
    };
  },
  methods: {
    // 上传
    async uploadImagenew(event) {
      try {
        console.log(111);
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.storeInfo.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 店铺设置/进店攻略-查看，传入活动id。
    async getPageShop() {
      try {
        const data = await MarketingAjax.getPageShop(this.activityID);
        if (data.data) {
          this.storeInfo = data.data;
          if (!this.storeInfo.showType) {
            this.storeInfo.showType = "all";
          }
        }else{
          
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 确认新建或编辑店铺设置
    async submitPageShop() {
      try {
        this.storeInfo.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.storeInfo));
        console.log(act);
        // 验证数据
        if (!act.logo) {
          this.$message.warning("请添加头图");
          return;
        }
        if (this.isNullOrEmpty(act.notice)) {
          this.$message.warning("滚动公告不能为空");
          return;
        }
        await MarketingAjax.savePageShop(act);
        this.$message.success("编辑店铺设置成功");
        await this.getPageShop();
      } catch (error) {
        this.$message.error(error);
      }
    }
  },
  created() {
    this.getPageShop();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image"
      });
    });
  }
};
</script>
<style lang="scss" scoped>
.shop-wrapper {
  .table-title {
    font-weight: 600;
    font-size: 18px;
    margin: 20px;
  }
  .preview-left,
  .phone-previvew {
    width: 375px;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .phone-body,
  .set-preview {
    height: 600px;
    overflow-y: scroll;
    padding: 5px;
    box-sizing: border-box;
  }
  .setting-box {
    margin-left: 40px;
    background: #fff;
    flex: 1;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>