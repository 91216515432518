import XHR from '@/utils/https/xhr';
const req = new XHR();

/**
 * 获取家具预热页列表
 * @param {Number} zbid
 * @param {Number} pageindex
 * @param {Number} pagesize
 * @param {Number} sortfield id
 * @param {string} sortby desc
 * @return void
 */
export const GetPreheatPageList = data => req.post('/liveajax/GetHomeLiveInfos', data);

// 保存预热页信息
export const SaveHomeLiveInfo = data => req.post('/liveajax/SaveHomeLiveInfo', data);

/**
 * 获取预热页信息
 * @param {Object} { zbid, topicid }
 * @return void
 */
export const GetHomeLiveInfo = data => req.post('/liveajax/GetHomeLiveInfo', data);

/**
 * 获取报名有礼明细
 * @param {Object} { zbid, topicid }
 * @return void
 */
export const GetApplyLottery = data => req.post('/liveajax/GetApplyLottery', data);

/**
 * 获取所有已选的品牌商列表
 * @param {Object} { liveinfoid, zbid }
 * @return void
 */
export const GetBrandOwnerList = data => req.post('/liveajax/GetALLInfoPageBrandOwnerList', data);

/**
 * 获取家具预热页明细-选择话题列表
 * @param {Object} { zbid, keyword }
 * @return void
 */
export const GetHomeLiveTopics = data => req.post('/liveajax/GetHomeLiveTopics', data);


/**
 * 获取家具预热页数据
 * @param {Object} { zbid, infopageid }
 * @return void
 */
export const GetHomeLiveInfoData = data => req.post('/liveajax/GetHomeLiveInfoData', data);

/**
 * 秒杀任务用户列表
 * @param {Object} { zbid, infopageid }
 * @return void
 */
export const GetSeckillTaskUserList = data => req.post('/liveajax/GetSeckillTaskUserList', data);

/**
 * 获取秒杀订单
 * @param {Object} { zbid, infopageid }
 * @return void
 */
export const GetSeckillOrderList = data => req.post('/liveajax/GetSeckillOrderList', data);

/*
 * 获取家具秒杀活动列表
 * @param {Object} { zbid, title, status 1生效并且没有绑定预热页 }
 * @return void
 */
export const GetHomeSeckillActivitys = data => req.post('/liveajax/GetHomeSeckillActivitys', data);

/**
 * 获取家具秒杀活动详情
 * @param {Object} { zbid, id }
 * @return void
 */
export const GetHomeSeckillActivity = data => req.post('/liveajax/GetHomeSeckillActivity', data);

/**
 * 获取家具秒杀活动列表
 * @param {Object} { zbid, id, status 1生效,0失效,-1删除 }
 * @return void
 */
export const HandleHomeSeckillActivity = data => req.post('/liveajax/HandleHomeSeckillActivity', data);

/**
 * 获取家具秒杀活动列表
 * @param {Object} { zbid, id, status 1生效,0失效,-1删除 }
 * @return void
 */
export const SaveHomeSeckillActivity = data => req.post('/liveajax/SaveHomeSeckillActivity', data);

/**
 * 获取家具秒杀活动明细数据
 * @param {Object} { zbid, id }
 * @return void
 */
export const GetHomeSeckillActivityData = data => req.post('/liveajax/GetHomeSeckillActivityData', data);

/**
 * 获取秒杀活动的秒杀商品列表
 * @param {Object} { zbid, id }
 * @return void
 */
export const GetHomeSeckillActivityProducts = data => req.post('/liveajax/GetHomeSeckillActivityProducts', data);
