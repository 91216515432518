import XHR from '@/utils/https/xhr';

const req = new XHR();


/**
 * 获取赠品列表
 * @param {Object} { zbid, name,compid,status,pageindex,pagesize }
 * @return void
 */
export const GetComplimentaryList = data => req.post('/liveajax/GetComplimentaryList', data);



/**
 * 保存赠品数据
 * @param {Object} { id,zbid, Name,ImgUrl,Detail,stock }
 * @return void
 */
export const SaveComplimentary = data => req.post('/liveajax/SaveComplimentary', data);


/**
 * 修改赠品状态
 * @param {Object} { zbid, id,statu }
 * @return void
 */
export const UpdateComplimentaryStatus = data => req.post('/liveajax/UpdateComplimentaryStatus', data);


/**
 * 赠品中奖列表
 * @param {Object} { zbid, awId 奖品id,username,userid,State 0未核销  1已核销,StartTime,EndTime,activityName,VerifyCode,pageindex,pagesize }
 * @return void
 */
export const GetUserAwardsList = data => req.post('/liveajax/GetUserAwardsList', data);

/**
 * 获取赠品关联的活动
 * @param {Object} { zbid, awId 奖品id }
 * @return void
 */
export const GetApplyLotteryNameList = data => req.post('/liveajax/GetApplyLotteryNameList', data);


/**
 * 核销中奖
 * @param {Object} { zbid, id }
 * @return void
 */
export const UpdateVerifyCode = data => req.post('/liveajax/UpdateVerifyCode', data);


