<template>
  <div>
    <div class="disflex flexdr mb10 w50p">
      <el-input placeholder="请输入话题名称/ID" style="width: 360px;" v-model="queryPara.keyword" class="input-with-select">
        <el-button slot="append" @click="searchbrand" icon="el-icon-search"></el-button>
      </el-input>
      <div :class="{ml10:queryPara.keyword.length}">
        <el-button type="danger" @click="cancelSearch" v-show="queryPara.keyword.length" >撤销搜索</el-button>
      </div>
    </div>
    <div style="height: 350px;width:100%;overflow-y: scroll;">
      <el-checkbox-group v-if="multiple" style="width:100%" v-model="checkedTopic">
        <el-table
          :data="topicList"
          border
          style="width:100%"
        >
          <el-table-column
            label="话题ID"
          >
            <div slot-scope="scope">
              <el-checkbox :label="scope.row.id" :key="scope.row.id">{{scope.row.id}}</el-checkbox>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            label="话题名称"
          >
          </el-table-column>
          <el-table-column
            v-show="fun == 'GetHomeMedalTopics'"
            label="话题类型"
            width="80"
          >
            <div slot-scope="scope">
              {{scope.row.types == 0 ? '公开' : scope.row.types == 1 ? '加密' : scope.row.types == 2 ? '付费':'公开'}}
            </div>
          </el-table-column>
          <el-table-column
            label="话题封面"
          >
            <div slot-scope="scope" class="">
              <img :src="scope.row.topicBanner|formaturl" style="width: 90px;height: 53px;" >
            </div>
          </el-table-column>
          <el-table-column
            prop="addtime"
            label="直播开始时间">
          </el-table-column>
        </el-table>
      </el-checkbox-group>
      <el-radio-group v-else style="width:100%" v-model="checkedTopic">
        <el-table
          :data="topicList"
          border
          style="width:100%"
        >
          <el-table-column
            label="话题ID"
          >
            <div slot-scope="scope">
              <el-radio :label="scope.row.id" :key="scope.row.id">{{scope.row.id}}</el-radio>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            label="话题名称"
          >
          </el-table-column>
          <el-table-column
            label="话题封面"
            width="120"
          >
            <div slot-scope="scope" class="">
              <img :src="scope.row.topicBanner|formaturl" style="width: 90px;height: 53px;" >
            </div>
          </el-table-column>
          <el-table-column
            v-show="fun == 'GetHomeMedalTopics'"
            label="话题类型"
            width="80"
          >
            <div slot-scope="scope">
              {{scope.row.types == 0 ? '公开' : scope.row.types == 1 ? '加密' : scope.row.types == 2 ? '付费':'公开'}}
            </div>
          </el-table-column>
          <el-table-column
            prop="starttime"
            label="直播开始时间">
          </el-table-column>
          <el-table-column
            v-show="fun == 'GetHomeMedalTopics'"
            prop="addtime"
            label="创建时间">
          </el-table-column>
        </el-table>
      </el-radio-group>
    </div>
    <div class="disflex flexdr justifysb mt10">
      <!-- <el-button type="primary" plain size="small" v-if="multiple" @click="toggleAllRadio" style="width:100px;">{{checkedTopic.length !== topicList.length ? '全选' : '取消全选'}}</el-button> -->
      <el-pagination
        layout="total, prev, pager, next,jumper"
        :total="totalNumber"
        @current-change="GetHomeLiveTopics"
      >
      </el-pagination>
    </div>
    <div class="disflex flexdr justifycc mt10">
      <el-button @click="cancelCheck">取消</el-button>
      <el-button @click="commitCheck" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
import { GetHomeLiveTopics, GetHomeMedalTopics, GetAudtHomeLiveTopicList } from "@/utils/https/modules/common.api.js";
export default {
  props: {
    pageid: {
      type: [Number, String],
      default: 0
    },
    zbid: {
      type: [Number, String],
      default: 0
    },
    tpid: {
      type: [Number, String],
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isshow: {
      type: Boolean,
      default: false
    },
    fun: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryPara: {
        pageindex: 1,
        pagesize: 10,
        keyword: ""
      },
      topicList: [],
      totalNumber: 0,
      checkedTopic: 0,
      savedCheckedTopic: [],//缓存已选择的话题
      isIndeterminate: true
    };
  },
  filters: {
    formaturl (value) {
      const prefix = 'https://j.weizan.cn/images/vzanbg/';
      if (value.includes('http')) {
        return value;
      } else {
        return prefix + value;
      }
    }
  },
  watch: {
    ids (value) {
      this.checkedTopic = value;
    },
    tpid (value) {
      this.checkedTopic = value;
    },
    isshow (value) {
      if (value) {
        this.checkedTopic = this.multiple ? this.ids : this.tpid;
        this.GetHomeLiveTopics();
        if (!this.multiple && this.savedCheckedTopic.length == 0) {
          this.savedCheckedTopic = { id: 0 };
        }
      }
    }
  },
  methods: {
    /**
     * 撤销搜索
     * @return void
     */
    cancelSearch () {
      this.checkedTopic =  this.multiple ? [] : 0;
      this.queryPara.keyword = ''
      this.queryPara.pageindex = 1;
      this.GetHomeLiveTopics();
    },
    getData (data) {
      if (this.fun == 'GetAudtHomeLiveTopicList') {
        return GetAudtHomeLiveTopicList(data)
      } else if (this.fun) {
        return GetHomeMedalTopics(data)
      } else {
        return GetHomeLiveTopics(data)
      }
    },
    /**
     * 获取品牌列表
     * @return void
     */
    GetHomeLiveTopics (page = 1) {
      this.queryPara.pageindex = page;
      this.getData({
        zbid: this.zbid,
        ...this.queryPara
      }).then(res => {
        this.topicList = res.dataObj;
        this.totalNumber = res.Amout;
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    /**
     * 取消选择
     * @return void
     */
    cancelCheck () {
      // this.checkedTopic =  this.multiple ? [] : 0;
      this.pageindex = 1;
      this.topicList = [];
      this.checkedTopic =  this.multiple ? this.savedCheckedTopic : 0;
      this.$emit('close', this.savedCheckedTopic);
    },
    /**
     * 提交选择
     * @return void
     */
    commitCheck () {
      const value = (this.checkedTopic).toString();
      if (value == 0 || value == '') {
        if (this.required) {
          this.$message.error('请选择你的话题')
          return;
        } else {
          this.$emit('multiple', this.multiple ? [] : 0);
          return;
        }
      } else {
        if (this.multiple) {
          this.savedCheckedTopic = this.checkedTopic
          this.$emit('complete', this.savedCheckedTopic);
        } else {
          const item = this.topicList.find(el => el.id == this.checkedTopic);
          this.$emit('complete', item);
        }
      }
      this.pageindex = 1;
      this.topicList = [];
      this.checkedTopic = this.multiple ? this.savedCheckedTopic : 0;
    },
    /**
     * 搜索品牌
     * @return void
     */
    searchbrand () {
      this.checkedTopic =  this.multiple ? [] : 0;
      this.GetHomeLiveTopics(1);
    },
    /**
     * 全选
     * @return void
     */
    toggleAllRadio(){
      if(this.checkedTopic.length === this.topicList.length){
        this.checkedTopic = []
      }else{
        for(let item of this.topicList){
          this.checkedTopic.push(item.id)
        }
      }
    }
  },
  mounted () {
    this.checkedTopic = this.multiple ? this.ids : this.tpid;
    this.GetHomeLiveTopics();
  }
}
</script>

<style lang="scss" scoped>

</style>