<template>
  <div class="">
    <!-- <el-tabs v-model="currView">
      <el-tab-pane label="首页设置" name="IndexSetting">
        <index-setting :activityID="activityID"></index-setting>
      </el-tab-pane>
      <el-tab-pane label="店铺设置" name="ShopSetting">
        <shop-setting :activityID="activityID"></shop-setting>
      </el-tab-pane>
    </el-tabs>-->
    <el-tabs v-model="currView">
      <el-tab-pane label="首页设置" name="IndexSetting"></el-tab-pane>
      <el-tab-pane label="店铺设置" name="ShopSetting"></el-tab-pane>
      <el-tab-pane label="页面风格" name="SkinSetting"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
import IndexSetting from "./SettingChildred/IndexSetting";
import ShopSetting from "./SettingChildred/ShopSetting";
import SkinSetting from "./SettingChildred/SkinSetting";

export default {
  name: "Settings", // 页面配置
  components: {
    IndexSetting: () => import("./SettingChildred/IndexSetting"),
    ShopSetting: () => import("./SettingChildred/ShopSetting"),
    SkinSetting: () => import("./SettingChildred/SkinSetting"),
  },
  props: {},
  data() {
    return {
      currView: "IndexSetting",
      activityID: 0
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.wrapper {
}
</style>
