import XHR from '@/utils/https/xhr';

const req = new XHR();

/**
 * 卡券礼包列表
 * @param {Object}
 * @return void
 */
export const GetCouponBags = data => req.post('/liveajax/GetCouponBags', data);

/**
 * 卡券礼包 编辑数据 0：开启,1：编辑,2：删除
 * @param {Object}
 * @return void
 */
export const HandleCouponBag = data => req.post('/liveajax/HandleCouponBag', data);

/**
 * 卡券礼包保存 
 * @param {Object} http://192.168.30.110:3000/project/11/interface/api/582
 * @return void
 */
export const SaveCouponBag = data => req.post('/liveajax/SaveCouponBag', data);

/**
 * 卡券礼包明细 
 * @param {Object}
 * @return void
 */
export const GetCouponBag = data => req.post('/liveajax/GetCouponBag', data);

/**
 * 获取礼包下面的优惠券信息
 * @param {Object} { zbid, couponBagId }
 * @return void
 */
export const GetBagCouponList = data => req.post('/liveajax/GetBagCouponList', data);

/*
 * 卡券礼包数据 
 * @param {Object}
 * @return void
 */
export const GetCouponBagData = data => req.post('/liveajax/GetCouponBagData', data);
