var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "首页设置", name: "IndexSetting" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "店铺设置", name: "ShopSetting" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "页面风格", name: "SkinSetting" }
          })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currView, {
            tag: "component",
            attrs: { activityID: _vm.activityID }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }