var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shop-wrapper disFlex" }, [
    _c("div", [
      _c("div", { staticClass: "phone-previvew mr20 bor1d pr br4" }, [
        _c("img", {
          staticStyle: { width: "375px", height: "560px" },
          attrs: { src: _vm.skinImg }
        })
      ])
    ]),
    _c("div", { staticClass: "setting-box" }, [
      _c(
        "div",
        { staticClass: "table-title" },
        [
          _c("el-alert", {
            attrs: {
              title:
                "温馨提示：切换页面风格后，自定义导航图标将会失效并恢复为初始默认图标，可进行重新上传",
              type: "warning",
              closable: false
            }
          }),
          _c("div", { staticClass: "skin-list disFlex" }, [
            _c(
              "div",
              {
                staticClass: "blue-skin skinitem",
                on: {
                  click: function($event) {
                    _vm.Default = "blue"
                    _vm.skinImg = _vm.blueSkin
                  }
                }
              },
              [
                _vm.Default == "blue"
                  ? _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: {
                        color: "#fff",
                        "font-size": "40px !important"
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "red-skin skinitem",
                staticStyle: { "margin-left": "60px" },
                on: {
                  click: function($event) {
                    _vm.Default = "red"
                    _vm.skinImg = _vm.redSkin
                  }
                }
              },
              [
                _vm.Default == "red"
                  ? _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: {
                        color: "#fff",
                        "font-size": "40px !important"
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "purple-skin skinitem",
                staticStyle: { "margin-left": "60px" },
                on: {
                  click: function($event) {
                    _vm.Default = "purple"
                    _vm.skinImg = _vm.purpleSkin
                  }
                }
              },
              [
                _vm.Default == "purple"
                  ? _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: {
                        color: "#fff",
                        "font-size": "40px !important"
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "green-skin skinitem",
                staticStyle: { "margin-left": "60px" },
                on: {
                  click: function($event) {
                    _vm.Default = "green"
                    _vm.skinImg = _vm.greenSkin
                  }
                }
              },
              [
                _vm.Default == "green"
                  ? _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: {
                        color: "#fff",
                        "font-size": "40px !important"
                      }
                    })
                  : _vm._e()
              ]
            )
          ]),
          _c(
            "el-button",
            {
              staticClass: "confirmbutton",
              staticStyle: { width: "300px" },
              attrs: { type: "primary" },
              on: { click: _vm.saveStyle }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }