var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vz-material-wrap" }, [
    _c(
      "p",
      {
        staticStyle: {
          "margin-bottom": "10px",
          color: "#aeaeae",
          "font-size": "14px",
          display: "flex",
          "justify-content": "space-between"
        }
      },
      [
        false
          ? _c(
              "el-checkbox",
              {
                attrs: { indeterminate: _vm.isIndeterminate },
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.checkAll,
                  callback: function($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll"
                }
              },
              [
                _vm._v(
                  "全选（" +
                    _vm._s(_vm.checkList.length) +
                    "）" +
                    _vm._s(_vm.max ? "最多" + _vm.max + "个" : "")
                )
              ]
            )
          : _vm._e(),
        _c("span", [
          _vm._v(
            "已选(" +
              _vm._s(_vm.checkList.length) +
              ")个/可选" +
              _vm._s(_vm.max ? _vm.max : 1) +
              "个"
          )
        ]),
        _c("span", [_vm._v(_vm._s(_vm.list.length) + "/" + _vm._s(_vm.total))])
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "list",
        staticClass: "vz-material-list",
        staticStyle: {
          "max-height": "400px",
          "overflow-y": "scroll",
          "margin-bottom": "10px"
        },
        on: { scroll: _vm.loadmore }
      },
      [
        _c(
          "el-checkbox-group",
          {
            staticStyle: { display: "flex", "flex-flow": "wrap" },
            on: { change: _vm.handleCheckItem },
            model: {
              value: _vm.checkList,
              callback: function($$v) {
                _vm.checkList = $$v
              },
              expression: "checkList"
            }
          },
          _vm._l(_vm.list, function(v, i) {
            return _c(
              "div",
              { key: v.Id, staticClass: "vzm-material-item" },
              [
                _c("el-checkbox", { attrs: { label: i } }, [
                  _vm.filetype == 3
                    ? _c("img", {
                        staticClass: "vzm-material",
                        attrs: { src: _vm._f("formatImage")(v.src) }
                      })
                    : _vm.filetype == 1
                    ? _c("img", {
                        staticClass: "vzm-image",
                        staticStyle: { "background-color": "rgba(0,0,0,0.5)" },
                        attrs: { src: "http://j.weizan.cn/zhibo/img/play.png" }
                      })
                    : _vm._e(),
                  _c("p", [_vm._v(_vm._s(v.FileName))])
                ])
              ],
              1
            )
          }),
          0
        ),
        _c(
          "p",
          { staticStyle: { "text-align": "center", "margin-bottom": "10px" } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.hasMore
                    ? _vm.isfetching
                      ? "加载中"
                      : "下拉加载更多"
                    : "已加载全部"
                ) +
                " "
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [
        _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.complete } },
          [_vm._v("完成")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }