var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.plainType == "set",
            expression: "plainType == 'set'"
          }
        ],
        staticClass: "disflex flexdr"
      },
      [
        _c("div", [
          _c("div", { staticClass: "phone-previvew mr20 bor1d pr br4" }, [
            _c("img", {
              staticClass: "w100p phone-header",
              attrs: {
                src:
                  "https://j.weizan.cn/livecontent/img/Addquestionnaire/header.jpg",
                alt: ""
              }
            }),
            _c(
              "div",
              { ref: "modulesView", staticClass: "set-preview bgeee pr" },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "w100p",
                    on: { change: _vm.modulesSortEnd },
                    model: {
                      value: _vm.modules,
                      callback: function($$v) {
                        _vm.modules = $$v
                      },
                      expression: "modules"
                    }
                  },
                  _vm._l(_vm.modules, function(v, j) {
                    return _c(
                      "div",
                      {
                        key: j,
                        staticClass: "modules-item ofh pr",
                        class: {
                          act: _vm.moduleIndex === j,
                          bgfff: v.type !== 2,
                          bgeee: v.type === 2
                        },
                        attrs: { type: v.type },
                        on: {
                          click: function($event) {
                            return _vm.getModule(j, v.type)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-delete pa remove-modules",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.removeModule(j)
                            }
                          }
                        }),
                        v.type === 7
                          ? _c(
                              "div",
                              {
                                staticClass: "video-mul w100p pr ofh",
                                style: { height: v.cover ? "auto" : "130px" }
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: v.cover,
                                      expression: "v.cover"
                                    }
                                  ],
                                  staticClass: "w100p br4",
                                  staticStyle: { height: "auto" },
                                  attrs: { src: v.cover }
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !v.cover,
                                        expression: "!v.cover"
                                      }
                                    ],
                                    staticClass: "mt20 fz14 tc"
                                  },
                                  [_vm._v("请上传图片")]
                                )
                              ]
                            )
                          : _vm._e(),
                        v.type === 2
                          ? _c(
                              "div",
                              {
                                staticClass: "video-mul w100p pr ofh",
                                style: { height: v.cover ? "auto" : "130px" }
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: v.cover,
                                      expression: "v.cover"
                                    }
                                  ],
                                  staticClass: "w100p br4",
                                  staticStyle: { height: "auto" },
                                  attrs: { src: v.cover }
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !v.cover,
                                        expression: "!v.cover"
                                      }
                                    ],
                                    staticClass: "mt20 fz14 tc"
                                  },
                                  [_vm._v("请上传图片")]
                                )
                              ]
                            )
                          : _vm._e(),
                        v.type === 3
                          ? _c(
                              "div",
                              {
                                staticClass: "video-mul w100p pr ofh",
                                style: { height: v.cover ? "auto" : "130px" }
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: v.cover,
                                      expression: "v.cover"
                                    }
                                  ],
                                  staticClass: "w100p br4",
                                  staticStyle: { height: "auto" },
                                  attrs: { src: v.cover }
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !v.cover,
                                        expression: "!v.cover"
                                      }
                                    ],
                                    staticClass: "mt20 fz14 tc"
                                  },
                                  [_vm._v("请上传图片")]
                                )
                              ]
                            )
                          : _vm._e(),
                        v.type === 6
                          ? _c(
                              "div",
                              {
                                staticClass: "video-mul w100p pr ofh",
                                style: { height: v.cover ? "auto" : "130px" }
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: v.cover,
                                      expression: "v.cover"
                                    }
                                  ],
                                  staticClass: "w100p br4",
                                  staticStyle: { height: "auto" },
                                  attrs: { src: v.cover }
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !v.cover,
                                        expression: "!v.cover"
                                      }
                                    ],
                                    staticClass: "mt20 fz14 tc"
                                  },
                                  [_vm._v("请上传图片")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(v.content, function(k, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "pr mul-item-i fl",
                              class: [
                                v.type !== 0 || k.style ? "w100p" : "w50p"
                              ],
                              attrs: { "data-index": i }
                            },
                            [
                              v.type === 0
                                ? _c("div", { staticClass: "img-item-ml" }, [
                                    _c("img", {
                                      attrs: { src: k.src, alt: "" }
                                    })
                                  ])
                                : _vm._e(),
                              v.type === 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "video-mul w100p pr",
                                      style:
                                        "background-image: url(" + v.cover + ")"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "play-icon-ml pa p-cc",
                                        attrs: {
                                          src:
                                            "https://j.weizan.cn/zhibo/img/play.png"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !v.content.length &&
                                  (v.type === 1 || v.type == 0),
                                expression:
                                  "!v.content.length &&(v.type===1||(v.type==0))"
                              }
                            ],
                            staticClass: "mt20 cae fz14 tc"
                          },
                          [
                            _vm._v(
                              " 请设置" +
                                _vm._s(
                                  v.type === 0
                                    ? "图片"
                                    : v.type === 1
                                    ? "视频"
                                    : v.type === 3
                                    ? "商品"
                                    : v.type === 5
                                    ? "优惠券礼包"
                                    : "优惠券"
                                ) +
                                "内容 "
                            )
                          ]
                        )
                      ],
                      2
                    )
                  }),
                  0
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "si-setting boxS p20 bor1d br4",
            staticStyle: { height: "auto" }
          },
          [
            _c("input", {
              ref: "uploadByModuleImageEl",
              attrs: {
                type: "file",
                accept: "image/*",
                "data-type": "customModule",
                hidden: "",
                id: "image-module"
              },
              on: { change: _vm.uploadFile }
            }),
            _c("input", {
              ref: "uploadByModuleVideoEl",
              attrs: {
                type: "file",
                accept: "video/*",
                "data-type": "customModule",
                hidden: "",
                id: "video-module"
              },
              on: { change: _vm.uploadFile }
            }),
            _c("div", { staticClass: "top-text-tips" }, [
              _c(
                "p",
                { staticClass: "h30 lh30 fz16 fb c34 borb1e flex1 pb5" },
                [_vm._v("选择组件")]
              ),
              _c("div", { staticClass: "mt10 mb10 cae fz14 auto-tips" }, [
                _vm._v("提示：点击下方组件添加至自定义区域")
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "set-options bottom-fiexd-btn disflex flexwrap pb20"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 0 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(0)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 添加图片(" +
                          _vm._s(_vm.modulesUseTimes.image) +
                          "/10)"
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 1 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 添加视频(" +
                          _vm._s(_vm.modulesUseTimes.video) +
                          "/10)"
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 2 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(2)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 添加卡券(" +
                          _vm._s(_vm.modulesUseTimes.coupon) +
                          "/1)"
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 3 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(3)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 添加秒杀活动(" +
                          _vm._s(_vm.modulesUseTimes.goods) +
                          "/1)"
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 6 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(6)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 添加集赞(" + _vm._s(_vm.modulesUseTimes.like) + "/1)"
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m10",
                      attrs: { type: _vm.moduleType === 7 ? "primary" : "" },
                      on: {
                        click: function($event) {
                          return _vm.pushModule(7)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "+ 公众号引流(" +
                          _vm._s(_vm.modulesUseTimes.drainage) +
                          "/1)"
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "top-text-tips" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.moduleType === 0 || _vm.moduleType === 1,
                      expression: "moduleType === 0 || moduleType === 1"
                    }
                  ],
                  staticClass: "mt20 mb20 auto-tips fz14"
                },
                [
                  _c("p", { staticClass: "c34 mb10" }, [_vm._v("操作步骤：")]),
                  _c("p", { staticClass: "cae" }, [
                    _vm._v("1.选中左侧组件模块")
                  ]),
                  _c("p", { staticClass: "cae" }, [
                    _vm._v("2.于下方设置内容样式")
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.moduleType >= 2,
                      expression: "moduleType >=2"
                    }
                  ],
                  staticClass: "mt20 mb20 auto-tips fz14"
                },
                [
                  _c("p", { staticClass: "c34 mb10" }, [_vm._v("操作步骤：")]),
                  _c("p", { staticClass: "cae" }, [
                    _vm._v("1.通过左侧预览图视窗可以实现拖拽改变组件位置")
                  ]),
                  _c("p", { staticClass: "cae" }, [
                    _vm._v(" 2.如需修改组件内容信息，请前往【营销设置】 ")
                  ])
                ]
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.modules.length,
                    expression: "modules.length"
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moduleType === 0,
                        expression: "moduleType === 0"
                      }
                    ],
                    staticClass: "model-setting-plain"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "edite-item mb10" },
                      [
                        _vm._m(0),
                        _vm.modules[_vm.moduleIndex]
                          ? _c(
                              "el-radio-group",
                              {
                                staticClass: "pl10",
                                on: { change: _vm.updateImageStyle },
                                model: {
                                  value: _vm.modules[_vm.moduleIndex].cover,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.modules[_vm.moduleIndex],
                                      "cover",
                                      $$v
                                    )
                                  },
                                  expression: "modules[moduleIndex].cover"
                                }
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: 1, border: "" } },
                                  [_vm._v("平铺")]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: 0, border: "" } },
                                  [_vm._v("大小图")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "edite-item mb10" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "disflex flexdr mb10" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10 imgupload",
                              attrs: { plain: "", icon: "el-icon-upload" },
                              on: { click: _vm.uploadByModuleImage }
                            },
                            [_vm._v("本地上传")]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.modules[_vm.moduleIndex]
                      ? _c(
                          "div",
                          { staticClass: "edite-item mb10 edite-item-image" },
                          [
                            _c(
                              "draggable",
                              {
                                staticClass: "disflex flexwrap",
                                on: { change: _vm.imageModuleChange },
                                model: {
                                  value: _vm.modules[_vm.moduleIndex].content,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.modules[_vm.moduleIndex],
                                      "content",
                                      $$v
                                    )
                                  },
                                  expression: "modules[moduleIndex].content"
                                }
                              },
                              _vm._l(
                                _vm.modules[_vm.moduleIndex].content,
                                function(k, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass: "pr m10 my-image-list-model"
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "pa cfff cp p10 el-icon-delete",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.removeModuleItem(i, 0)
                                          }
                                        }
                                      }),
                                      _c("img", {
                                        staticClass:
                                          "edit-item-image bor1d br4",
                                        attrs: { src: k.src }
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: k.value,
                                            expression: "k.value"
                                          }
                                        ],
                                        staticClass: "el-input__inner mt10",
                                        attrs: {
                                          placeholder: "请输入跳转链接"
                                        },
                                        domProps: { value: k.value },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              k,
                                              "value",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.plainType === "set",
                            expression: "plainType === 'set'"
                          }
                        ],
                        staticClass: "disflex p20 boxS"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "375px" },
                            attrs: { loading: _vm.btnloading, type: "primary" },
                            on: { click: _vm.savePage }
                          },
                          [_vm._v("保存设置")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moduleType === 1,
                        expression: "moduleType === 1"
                      }
                    ],
                    staticClass: "model-setting-plain"
                  },
                  [
                    _c("div", { staticClass: "edite-item mb10" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "disflex flexdr" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              attrs: { plain: "", icon: "el-icon-upload" },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.uploadByModuleVideoEl.click()
                                }
                              }
                            },
                            [_vm._v("本地上传")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editModuleItem.src,
                              expression: "editModuleItem.src"
                            }
                          ],
                          staticClass: "disflex mt10"
                        },
                        [_vm._m(3)]
                      )
                    ]),
                    _c("div", { staticClass: "edite-item mb10" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "disflex flexdr" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10 imgupload",
                              attrs: { plain: "", icon: "el-icon-upload" },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.uploadByModuleImageEl.click()
                                }
                              }
                            },
                            [_vm._v("本地上传")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editModuleItem.cover,
                              expression: "editModuleItem.cover"
                            }
                          ],
                          staticClass: "disflex mt10"
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "375px",
                              height: "210px",
                              "object-fit": "cover"
                            },
                            attrs: { src: _vm.editModuleItem.cover }
                          })
                        ]
                      )
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt10 mb20",
                        staticStyle: { width: "375px" },
                        attrs: { loading: _vm.btnloading, type: "primary" },
                        on: { click: _vm.pushVideoInModule }
                      },
                      [_vm._v("保存设置")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moduleType === 2,
                        expression: "moduleType === 2"
                      }
                    ],
                    staticClass: "model-setting-plain"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "edite-item mb10" },
                      [
                        _vm._m(5),
                        _vm.modules[_vm.moduleIndex]
                          ? _c(
                              "el-radio-group",
                              {
                                staticClass: "pl10",
                                on: { change: _vm.updateCouponStyle },
                                model: {
                                  value: _vm.modules[_vm.moduleIndex].showType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.modules[_vm.moduleIndex],
                                      "showType",
                                      $$v
                                    )
                                  },
                                  expression: "modules[moduleIndex].showType"
                                }
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: 1, border: "" } },
                                  [_vm._v("卡包")]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: 2, border: "" } },
                                  [_vm._v("列表")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt10 mb20",
                        staticStyle: { width: "375px" },
                        attrs: { loading: _vm.btnloading, type: "primary" },
                        on: { click: _vm.savePage }
                      },
                      [_vm._v("保存设置")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moduleType === 3,
                        expression: "moduleType === 3"
                      }
                    ],
                    staticClass: "model-setting-plain"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "375px" },
                            attrs: { loading: _vm.btnloading, type: "primary" },
                            on: { click: _vm.savePage }
                          },
                          [_vm._v("保存设置")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moduleType === 6,
                        expression: "moduleType === 6"
                      }
                    ],
                    staticClass: "model-setting-plain"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "375px" },
                            attrs: { loading: _vm.btnloading, type: "primary" },
                            on: { click: _vm.savePage }
                          },
                          [_vm._v("保存设置")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm.modules[_vm.moduleIndex]
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.moduleType === 7,
                            expression: "moduleType === 7"
                          }
                        ],
                        staticClass: "model-setting-plain"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt20" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "375px" },
                                attrs: {
                                  loading: _vm.btnloading,
                                  type: "primary"
                                },
                                on: { click: _vm.savePage }
                              },
                              [_vm._v("保存修改")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fz14 c34 fb h30 lh30 mb10" }, [
      _c("i", { staticClass: "cred" }, [_vm._v("*")]),
      _vm._v("图片样式 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fz14 c34 fb h30 lh30 mb10" }, [
      _c("i", { staticClass: "cred" }, [_vm._v("*")]),
      _vm._v("添加图片 "),
      _c("i", { staticClass: "fz12 cae" }, [
        _vm._v("（ 建议宽高比16:9，支持JPG，PNG，JPEG格式，最大不超过4MB ）")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fz14 c34 fb h30 lh30 mb10" }, [
      _c("i", { staticClass: "cred" }, [_vm._v("*")]),
      _vm._v("视频 "),
      _c("i", { staticClass: "fz12 cae" }, [
        _vm._v("（ 建议视频宽高比16:9，支持MP4格式，最大不超过100M ）")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { width: "475px", height: "266px !important" } },
      [
        _c("video", {
          staticClass: "video-js vjs-big-play-centered",
          attrs: { controls: "", preload: "auto", id: "preview-video" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fz14 c34 fb h30 lh30 mb10" }, [
      _c("i", { staticClass: "cred" }, [_vm._v("*")]),
      _vm._v("封面图 "),
      _c("i", { staticClass: "fz12 cae" }, [
        _vm._v("（ 建议封面图宽高比16:9 ）")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fz14 c34 fb h30 lh30 mb10" }, [
      _c("i", { staticClass: "cred" }, [_vm._v("*")]),
      _vm._v("展示样式 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }