<template>
<div v-show="show" class="scratchmodel">
  <el-alert title="用户报名成功后，可参与刮奖活动，用奖品激励的方式可以吸引更多人报名哦。" type="info" class="mb10" :closable="false"></el-alert>
  <el-form ref="scratchform" :model="form" label-width="120px">
    <el-form-item :rules="validActivityendTime" prop="Activityendtime" label="活动截止时间:">
      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间" v-model="form.Activityendtime"></el-date-picker>
    </el-form-item>
    <div class="disflex flexdr">
      <el-form-item :rules="validRedeemStartTime" prop="Redeemstarttime" label="兑奖时间:">
        <el-date-picker  value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始时间" v-model="form.Redeemstarttime" :picker-options="redeemStartDatePicker" :disabled="this.disabledRedeemstarttime"></el-date-picker>
      </el-form-item>
      <span class="pl10 pr10 h35 lh35 inlineb" >-</span>
      <el-form-item label-width="0" :rules="validRedeemEndTime" prop="Redeemendtime" >
        <el-date-picker :rules="validRedeemEndTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束时间" v-model="form.Redeemendtime" :picker-options="redeemEndDatePicker"></el-date-picker>
      </el-form-item>
    </div>
    <el-form-item label="限制中奖区域:">
      <el-radio-group v-model="form.Restrictapy" :disabled="form.Id>0">
        <el-radio :label="0">无限制</el-radio>
        <el-radio :label="1">服务区域内用户方可中奖</el-radio>
      </el-radio-group>
      <VDistpicker :disabled="form.Id>0" v-show="form.Restrictapy == 1" hide-area 
      :province="form.RestricProvince" 
      :city="form.RestricCity"
      :placeholders="{ province: '', city: '' }"
      @selected="bmylselect"
      v-model="form.Restric"></VDistpicker>
    </el-form-item>
    <el-form-item label="活动简介:">
      <el-input style="width: 430px;" type="text" maxlength="20" show-word-limit v-model="form.Name" placeholder="可获得最高5万元家居礼包 或 免费奖品哦！"></el-input>
    </el-form-item>
    <el-form-item label="活动规则:">
      <el-input style="width: 430px;" type="textarea" :rows=5 maxlength="800" v-model="form.Detail" placeholder="建议填写活动的具体规则，如：中奖用户需到指定门店进行奖品兑换..."></el-input>
      <p class="fz12 cae"> <i class="cred" v-if="form.Detail" >{{form.Detail.length}}</i>/800</p>
    </el-form-item>
    <el-form-item label="添加奖品:">
      <p class="p10 pl0 cae fz14" style="line-height: 22px;">奖品池最大中奖率为100%，每款奖品所设置的中奖率会占奖品池一定的百分比例，单款奖品可用库存不足时，会减去对应的中奖率百分比，当所有奖品中奖率总和达到100%时，将无法再添加奖品。活动开始后，奖品不可删除，请谨慎设置。</p>
      <div class="p10 pl0" >
        <div class="pr inlineb">
          <el-button :disabled="form.ComplimentaryRelationList.length>=20" type="primary" @click="openGiftList" icon="el-icon-plus" plain>添加奖品</el-button>
          <div aria-hidden="false" class="el-tooltip__popper is-light" x-placement="right" style="position: absolute; top:2px;left: 121px;transform-origin: left center;width: 220px;">
            现已添加{{form.ComplimentaryRelationList.length}}款奖品，最多可添加20款。
            <div  class="popper__arrow" style="top: 8px;"></div>
          </div>
        </div>
      </div>
      <el-table border="" :data="form.ComplimentaryRelationList">
        <el-table-column
          label="奖品"
          prop="Name"
        ></el-table-column>
        <el-table-column
          label="已用/可用"
        >
          <span slot-scope="scope">{{scope.row.SalesVolume}}/{{scope.row.LockStock}}</span>
        </el-table-column>
        <el-table-column
          label="中奖概率"
        >
          <span slot-scope="scope">{{scope.row.WinningRatio}}%</span>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <div class="diflex flexdr" slot-scope="scope">
            <el-tag class="cp" @click="editeChooseGift(scope.$index)">编辑</el-tag>
            <el-tag type="danger" class="ml10 cp" v-show="scope.row.relationId==null" @click="deleteChooseGift(scope.$index)" >删除</el-tag>
          </div>
        </el-table-column>
      </el-table>
      <p class="p10 pl0 cae fz14" style="line-height: 22px;"><i class="fz14 el-icon-medal-1 mr5"></i>你当前所配置的奖品池总中奖率为 <i class="cred">{{totalRate}}%</i> ，用户不中奖的概率为 <i class="cred">{{hasWinRate}}%</i>。</p>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">{{form.id == 0 ? '立即创建':'保存编辑'}}</el-button>
      <el-button @click="cancel">取消</el-button>
      <el-button type="danger" @click="remove">删除</el-button>
    </el-form-item>
  </el-form>
  <!-- 添加奖品 dialog -->
  <el-dialog
    title="添加奖品"
    :visible.sync="showGitfList"
    :closeOnClickModal="false"
    width="50%"
  >
    <el-alert type="warning" :closable="false">
      <p>奖品来自于奖品池，更多奖品可再奖品池进行添加。<a :href="`/admin?zbid=${zbid}#/homelive/complimentaryList`" target="_block" class="cblue ml10" >去奖品池>></a></p>
    </el-alert>
    <el-steps style="width: 400px;" class="mauto mt10 mb10" :active="active">
      <el-step title="1 选择奖品"></el-step>
      <el-step title="2 设置奖品"></el-step>
    </el-steps>
    <!-- 第一步选择奖品 -->
    <div v-show="active == 1">
      <div class="p10 pl0 boxS">
        <el-row>
          <el-col :span="24">
            <el-input
              size="small"
              style="width:200px;margin-right:20px"
              placeholder="请输入奖品id"
              v-model="giftId"
            />
            <el-input
              size="small"
              style="width:200px;margin-right:20px"
              placeholder="请输入奖品名称"
              v-model="giftName"
            />
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="getComplimentaryList(1)"
              size="medium"
            >搜索</el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-refresh"
              @click="getComplimentaryList(1)"
              size="medium"
            >刷新</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :highlight-current-row="true" :height="listdata.length?400:100" :data="listdata" border v-loading="tableLoading">
        <el-table-column label="奖品ID" prop="id" align="center"></el-table-column>
        <el-table-column label="奖品名称" prop="Name" min-width="120" align="center"></el-table-column>
        <el-table-column label="奖品" min-width="120" align="center">
          <span slot-scope="scope">
            <img style="width: 48px; height: 48px; " :src="scope.row.ImgUrl" >
          </span>
        </el-table-column>
        <el-table-column label="已用/剩余" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{(scope.row.LockStock)+"/"+(scope.row.stock-scope.row.LockStock) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" align="center">
          <template slot-scope="scope">
            <el-tag class="cp" @click="getMyGift(scope.row)">添加</el-tag>
          </template>
        </el-table-column>
      </el-table>
      
      <div class="dialog-footer disflex flexdr justifysb mt20 alignic">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageindex"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          background
          :total="pageTotal"
        ></el-pagination>
        <div class="disflex h40 lh40">
          <div class="mr20" v-show="listdata.length">
            <el-button class="block mauto" type="primary" @click="toAddGift" >添加奖品</el-button>
          </div>
          <el-button @click="showGitfList = false">取 消</el-button>
        </div>
      </div>
    </div>
    <!-- 第二步编辑奖品信息 -->
    <div class="p20 boxS" v-show="active == 2">
      <el-form ref="formmodel" :model="editeModel" label-width="90px">
        <el-form-item label="奖品名称:">
          <el-input disabled :value="editeModel.Name"></el-input>
        </el-form-item>
        <el-form-item label="兑换说明:">
          <el-input type="textarea" disabled :value="editeModel.Detail"></el-input>
        </el-form-item>
        <el-form-item :rules="giftNumber" prop="InitialStock" label="奖品数量:">
          <div class="fz14 disflex flexdr alignic">
            <el-input type="text" style="width: 80px" v-model="editeModel.InitialStock"></el-input>
            <span class="ml5">份</span>
          </div>
          <p class="pr10 boxS" style="line-height: 22px;">(件数区间值0~9999，奖品数量只能增加不能减少，请谨慎设置，奖品数量不可超过库存量，当前可用库存数为{{editeModel.currentMaxStock}})</p>
        </el-form-item>
        <el-form-item prop="WinningRatio" :rules="winRateTRules" label="中奖率:">
          <div class="fz14 disflex flexdr alignic">
            <el-input type="text" style="width: 80px" v-model="editeModel.WinningRatio" :disabled="editeModel.relationId!=null"></el-input>
            <span class="ml5">%</span>
          </div>
          <p class="pr10 boxS" style="line-height: 22px;">(中奖率区间值0.001%~100%，中奖率不可超过奖品池剩余可配置的中奖率{{editeModel.hasWinRate}}%)</p>
        </el-form-item>
      </el-form>
      <div class="disflex flexdr justifycc">
        <el-button @click="pushGift" type="primary">{{editeIndex > 0 ? '保存编辑':'添加'}}</el-button>
        <el-button type="parimy" plain="" @click="active = 1" :disabled="form.ComplimentaryRelationList.length>=20">重新选择</el-button>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import VDistpicker from "v-distpicker";

import {
  GetComplimentaryList
} from "@/utils/https/HomeLive/complimentary";
import {
  SaveHomeLiveInfo,
  GetApplyLottery,
} from "@/utils/https/HomeLive/preheat";

import { clone } from '@/utils/utils.js';
// import { mapGetters } from "vuex";
export default {
  name: 'scratchmodel',
  props: {
    pageid: {
      type: Number,
      default: 0
    },
    tpid: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    },
    dataModel: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    VDistpicker
  },
  data () {
    return {
      redeemStartDatePicker: this.redeemStartDate(),
      redeemEndDatePicker: this.redeemEndDate(),

      form: {
        ComplimentaryRelationList: []
      },      
      // 截止初始化时间
      initActivityendtime:null,
      // 验证活动时间
      validActivityendTime: [
        { trigger: "blur", required: true, message: "请选择活动的截止时间" },
        {
          validator: (rule, value, callback) => {     
            const haftHour = 30 * 60 * 1000;
            const justNow = new Date();
            const actTime = new Date(value);
            if(this.initActivityendtime == null || actTime.getTime() != this.initActivityendtime.getTime()){
              if ((actTime - justNow) < haftHour) {
                callback(new Error('活动截止时间不可小于当前时间30分钟'))
              } else {
                callback()
              }
            }else {
              callback()
            }
          },
          trigger: "blur"
        }
      ],
      // 验证活动兑换开始时间
      validRedeemStartTime: [
        { trigger: "blur", required: true, message: "请选择兑换开始时间" }
      ],
      // 验证活动兑换结束时间
      validRedeemEndTime: [
        { trigger: "blur", required: true, message: "请选择兑换结束时间" },
        {
          validator:(rule, value, callback) => {
            // 24 hours
            const oneDay = (24 * 60 * 60 * 100);
            const starttimedate = new Date(this.form.Redeemstarttime);
            const activityendtime = new Date(this.form.Activityendtime);
            const startTime = starttimedate.getTime();
            const endTime = new Date(value).getTime();
            // 6 years
            const sixYear = starttimedate.getFullYear() + 6;
            const maxDate = this.form.Redeemstarttime.replace(starttimedate.getFullYear(), sixYear);
            if (endTime < (activityendtime.getTime() + oneDay)) {
              callback(new Error('兑换结束时间不可小于活动截止24小时'));
            }
            else if ((endTime - startTime) < oneDay) {
              callback(new Error('兑换时间间隔不可小于24小时'));
            } else if (maxDate < endTime) {
              callback(new Error('兑换时间跨度最长为6年'));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      // 中奖率
      winRateTRules: [
        { trigger: "blur", required: true, message: "请填写中奖率" },
        {
          validator: (rule, value, callback) => {
            let hasWinRate = this.hasWinRate;
            if(this.editeIndex >= 0){
              hasWinRate = (((hasWinRate*1000) + (this.form.ComplimentaryRelationList[this.editeIndex].WinningRatio)*1000))/1000;
            }
            const num = Number(value);
            if(!(/((^[1-9]\d*)|^0)(\.\d{1,3}){0,1}$/.test(value))){
              callback(new Error('最小仅限三位小数'));
            }
            if(!this.editeModel.relationId){
              if (num < 0.001) {
                callback(new Error('请填写大于0.001%的值，中奖率最小值为0.001%'));
              } else if (num > hasWinRate) {
                callback(new Error(`请填写小于${hasWinRate}的值，剩余可配置的中奖率${hasWinRate}%`));
              } else {
                callback();
              }
            }else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      // 奖品数量
      giftNumber: [
        { trigger: "blur", required: true, message: "请填写奖品数量" },
        {
          validator: (rule, value, callback) => {
            const num = Number(value);
            if (!Number.isInteger(num) || num < 0) {
              callback(new Error('请输入正整数'))
            } else if (this.editeModel.relationId == null && num > (this.editeModel.currentMaxStock)) {
              callback(new Error(`奖品剩余库存量 ${this.editeModel.currentMaxStock}`));
            } else if (this.editeModel.relationId !=null && num > (this.editeModel.currentMaxStock + this.editeModel.currentMinStock)) {
              callback(new Error(`奖品剩余库存量 ${this.editeModel.currentMaxStock + this.editeModel.currentMinStock}`));
            } else if (this.editeModel.relationId!=null && num < this.editeModel.currentMinStock){
              callback(new Error(`已保存的奖品，奖品数量不能小于当前设定值 ${this.editeModel.currentMinStock}`));
            } else {
              callback()
            }
          },
          trigger: "blur"
        }
      ],
      showGitfList: false,
      // 奖品列表
      giftName: '',
      giftId: '',
      tableLoading: false,
      listdata: [],
      pageTotal: 0,
      pageindex: 0,
      // 编辑的奖品
      editeModel: {},
      active: 1,
      // 可配置中奖率
      hasWinRate: 100,
      editeIndex: -1,
      // 已配置中奖率
      totalRate: 0,
      // 兑奖开始时间是否允许修改
      disabledRedeemstarttime:false,
      zbid:6
    }
  },
  watch: {
    show (value) {
      // 初始化活动数据
      if (value && Object.keys(this.form).length === 1) {
        this.updateform(this.dataModel);
      }
    },
    dataModel (value) {
      if (!Object.keys(value).length) {
        this.editeModel = {};
        this.editeIndex = -1;
        this.totalRate = 0;
        this.hasWinRate = 100;
      }
    }
  },
  // computed: {
  //   ...mapGetters(['zbid'])
  // },
  methods: {
    toAddGift () {
      window.open(`/admin?zbid=${this.zbid}#/homelive/complimentaryList`);
    },
    remove () {
      this.$confirm('删除后，活动将不可恢复，你确定要删除吗？', '提示').then(() => {
        this.editeModel = {};
        this.editeIndex = -1;
        this.totalRate = 0;
        this.hasWinRate = 100;
        this.form = { ComplimentaryRelationList: [] }
        this.$emit('remove');
      });
    },
    //更新form
    updateform(sourceModel){
      const cloneData = clone(sourceModel);
        cloneData.ComplimentaryRelationList = cloneData.ComplimentaryRelationList.map(el => {
          return {
            currentMinStock: el.InitialStock,
            currentMaxStock: el.SurplusStock,
            InitialStock: el.InitialStock,
            WinningRatio: el.WinningRatio / 1000,
            SalesVolume: el.SalesVolume,
            LockStock: el.LockStock,
            Name: el.Name,
            Detail: el.Detail,            
            id: el.Compid,
            relationId: el.Id,
          }
        });
        if(cloneData.Id > 0){
          const redeemstarttime =new Date(cloneData.Redeemstarttime);
          if(redeemstarttime.getTime() <= Date.now()){
            this.disabledRedeemstarttime = true;
          }
        }else {
          this.disabledRedeemstarttime = false;
        }
        if(!cloneData.Detail){
          cloneData.Detail="";
        }        
        this.form = cloneData;
        this.initActivityendtime = new Date(cloneData.Activityendtime);
        this.updateRote();
    },
    // 编辑已选奖品
    editeChooseGift (index, isfromlist) {
      if (isfromlist !== 'fromlist') {
        this.openGiftList();
      }
      this.active = 2;
      const cloneData = clone(this.form.ComplimentaryRelationList[index]);
      this.editeIndex = index;
      let hasWinRate = this.hasWinRate;
      if(index >= 0){
        hasWinRate = (((hasWinRate*1000) + (this.form.ComplimentaryRelationList[this.editeIndex].WinningRatio)*1000))/1000;
      }
      this.editeModel = Object.assign({
          hasWinRate: hasWinRate
        }, cloneData);
      this.$nextTick(() => {
        this.updateRote();
      })
    },
    // 删除已选奖品
    deleteChooseGift (index) {
      this.$confirm('确定要移除该商品吗？', '提示').then(() => {
        this.form.ComplimentaryRelationList.splice(index, 1);
        this.$nextTick(() => {
          this.updateRote();
        })
      }).catch(() => {
        this.$message.info('删除已取消');
      })
    },
    // 添加商品
    pushGift () {
      this.$refs.formmodel.validate((isok) => {
        if (isok) {
          // 编辑状态 替换编辑结果
          if (this.editeIndex >= 0) {
            const editem = clone(this.editeModel);
            editem.LockStock = editem.InitialStock - editem.SalesVolume;
            this.form.ComplimentaryRelationList.splice(this.editeIndex, 1, editem);
            this.$message.success('编辑成功');
          } else {
            // 新增一个奖品
            // 获取报名有礼 添加奖品 设置完成后的奖品
            const item = clone(this.editeModel);
            item.SalesVolume = 0;
            item.LockStock = item.InitialStock - item.SalesVolume;
            this.form.ComplimentaryRelationList.push(item);
            this.editeIndex = -1;
            this.$message.success('添加成功');
          }
          this.showGitfList = false;
          this.active = 1;
          this.$nextTick(() => {
            this.updateRote();
          });
        } else {
          this.$message.error('请根据提示填写完整的信息！');
        }
      });
    },
    // 更新计算剩余的 可配置中奖率
    updateRote () {
      let lessRote = 100;
      let totalRate = 0;
      this.form.ComplimentaryRelationList.forEach(el => {
        const value = Number(el.WinningRatio);
        // 剩余中奖率
        lessRote = ((lessRote*1000) - (value*1000))/1000;
        // 已配置中奖率
        totalRate = ((totalRate*1000) + (value*1000))/1000;
      });
      this.hasWinRate = lessRote;
      this.totalRate = totalRate;
    },
    // 打开奖品库列表
    openGiftList () {
      this.showGitfList = true;
      this.active = 1;
      this.getComplimentaryList();
    },
    // 奖品列表跳页
    handleSizeChange(val) {
      this.queryData.pagesize = Number(val);
      this.getComplimentaryList();
    },
    // 奖品列表切换页面
    handleCurrentChange(val) {
      this.getComplimentaryList(Number(val));
    },
    // 获取选择的礼物列表
    getMyGift (item) {
      const { id } = item;
      // 判断当前选择的奖品是否已存在奖品池中
      if (id) {
        const hasBeforIndex = this.form.ComplimentaryRelationList.findIndex(el => el.id === id);
        if (hasBeforIndex >= 0) {
          this.$confirm('该奖品已在奖品池中，请勿重复添加', '提示', {
            confirmButtonText: '去编辑'
          }).then(() => {
            // 去编辑
            this.editeChooseGift(hasBeforIndex, 'fromlist');
          });
          return;
        }
      }
      // 将选择的奖品 设置为编辑状态
      const cloneItem = clone(item);
      this.editeIndex = -1;
      this.editeModel = {
        currentMinStock: 1,
        currentMaxStock: cloneItem.stock - cloneItem.LockStock,
        SalesVolume: 0,
        id: cloneItem.id,
        Name: cloneItem.Name,
        Detail: cloneItem.Detail,
        stock: cloneItem.stock,
        InitialStock: 1,
        WinningRatio: 1,
        hasWinRate: this.hasWinRate
      };
      this.active = 2;
      this.$nextTick(() => {
        this.$refs.formmodel.validate();
      })
    },
    // 获取礼物列表
    getComplimentaryList (page = 1) {
      this.tableLoading = true;
      this.pageindex = page;
      GetComplimentaryList({
        zbid: this.zbid,
        name: this.giftName,
        compid: this.giftId,
        status: 1,
        pageindex: page
      }).then(res => {
        this.listdata = res.data || [];
        this.pageTotal = res.Amout;
      }).catch(msg => {
        this.$message.error(msg || "找不到相关的信息");
      }).finally(() => {
        setTimeout(() => {
          this.tableLoading = false;
        }, 500);
      });
    },
    cancel () {
      this.$emit('cancel');
    },
    // 城市选择
    bmylselect(data) {
      this.form.RestricProvince = data.province.value;
      this.form.RestricCity = data.city.value;
    },
    // 保存报名活动列表
    onSubmit () {
      this.$refs.scratchform.validate((valid) => {
        if (valid) {
          if (!this.form.ComplimentaryRelationList.length) {
            this.$message.error('请添加奖品！');
          } else {
            const formdata = clone(this.form);
            formdata.ComplimentaryRelationList = formdata.ComplimentaryRelationList.map(el => {
              return {
                InitialStock: el.InitialStock, //库存
                WinningRatio: el.WinningRatio * 1000, // 中奖率，0.001%=1
                compid: el.id // 奖品id
              }
            });
            // 是否开启区域限制
            if (formdata.Restrictapy) {
              if (formdata.RestricCity == '' || formdata.RestricProvince == '') {
                this.$message.error('请选择活动的限制区域');
                return;
              }
            }
            // 提交保存记录
            SaveHomeLiveInfo({
              id: this.pageid,
              zbid: this.zbid,
              topicid: this.tpid,
              opertype: 'applylottery',
              ApplyLotteryStr: JSON.stringify(formdata)
            }).then((res) => {
              // 新增状态
              //if (formdata.Id == 0) {
                this.form.id = res.data.applyLotteryId;
                if(res.data.applyLotteryId>0){
                  GetApplyLottery({
                    zbid: this.zbid,
                    topicid: this.tpid
                  }).then((res2) => {
                        this.updateform(res2.data);
                      })
                }
              //}
              this.$message.success(res.Msg);
            }).catch((errorMsg) => {
              this.$message.error(errorMsg);
            });
          }
        } else {
          this.$message.error('请根据提示填写完整的信息！');
          return false;
        }
      });
    },  
    redeemStartDate(){
      const self = this
      return {
        disabledDate(time){
          if (self.form.Redeemendtime) { // 如果结束时间不为空，则小于结束时间
            return (new Date(self.form.Redeemendtime).getTime()-86400000) < time.getTime();
          }
        }
      }
    },
    redeemEndDate() {
      const self = this
      return {
        disabledDate(time) {
          if (self.form.Redeemstarttime) { // 如果开始时间不为空，则结束时间大于开始时间
            return (new Date(self.form.Redeemstarttime).getTime()+86400000) > time.getTime();
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>