import { get, post } from "../Http.js";
import qs from "querystring";


// 获取直播间信息
export const getLiveStudioInfo = zbid => get("/liveajax/GetCurrentSiteInfo", { zbid });

// 获取直播间余额
export const GetSiteRemainMoney = zbid => post("/liveajax/GetSiteRemainMoney", qs.stringify({ siteId: zbid }));

//获取菜单栏
export const getRightList = (data) => post("http://192.168.30.29:10005/api/RightService/RightList", data);

//设置消息为已读
export const getSetMsgRead = (data) => post(`/liveajax/SetMsgRead?v=${new Date().getTime()}`, qs.stringify(data));
export const getSetMsgReadNew = (data) => post(`/liveajax/SetMsgReadNew?v=${new Date().getTime()}`, qs.stringify(data));

//设置所有消息为已读
export const getSetMsgAllRead = (data) => post(`/liveajax/SetMsgAllRead?v=${new Date().getTime()}`, qs.stringify(data));

//
export const getpenddeal = zid => post('/liveajax/penddeal', qs.stringify({zid}), false);

// 获取家具话题列表
export const GetHomeLiveTopics = data => post("/liveajax/GetHomeLiveTopics", qs.stringify(data));

// 获取家具话题列表(导购)
export const GetAudtHomeLiveTopicList = data => post("/liveajax/GetAudtHomeLiveTopicList", qs.stringify(data));

// 获取家具话题列表（绑定直播章）
export const GetHomeMedalTopics = data => post("/liveajax/GetHomeMedalTopics", qs.stringify(data));

/**
 * 获取家居品牌商列表
 * @param {Number} zbid
 * @param {Number} tpid
 * @param {string} name
 * @return void
 */
export const GetBrandOwnerData = data => post('/liveajax/GetBrandOwnerData', qs.stringify(data), false);
