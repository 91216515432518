var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shop-wrapper disFlex" }, [
    _c("div", [
      _c("div", { staticClass: "phone-previvew mr20 bor1d pr br4" }, [
        _c("img", {
          staticClass: "w100p phone-header",
          attrs: {
            src:
              "https://j.weizan.cn/livecontent/img/Addquestionnaire/header.jpg",
            alt: ""
          }
        }),
        _c(
          "div",
          {
            staticClass: "set-preview pr",
            staticStyle: { height: "auto", padding: "0" }
          },
          [
            _c("img", {
              staticStyle: { width: "375px" },
              attrs: {
                src:
                  "https://j.weizan.cn/zhibo/livecontent/homelive/img/store-image.png"
              }
            }),
            _c(
              "div",
              { staticClass: "pa top0 w100p left0 zindex1" },
              [
                _vm.storeInfo.logo
                  ? _c("img", {
                      staticClass: "w100p",
                      staticStyle: { height: "210px", "object-fit": "cover" },
                      attrs: { src: _vm.storeInfo.logo }
                    })
                  : _vm._e(),
                _c(
                  "marquee",
                  {
                    staticClass: "w100p h30 lh30",
                    staticStyle: {
                      "background-color": "#fceac2",
                      color: "#794315"
                    },
                    attrs: {
                      behavior: "behavior",
                      loop: "loop",
                      scrolldelay: "100",
                      scrollamount: "5"
                    }
                  },
                  [_vm._v(_vm._s(_vm.storeInfo.notice))]
                )
              ],
              1
            )
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "setting-box" },
      [
        _c("div", { staticClass: "table-title" }, [_vm._v("店面信息设置")]),
        _c(
          "el-form",
          {
            staticClass: "mt20",
            attrs: { model: _vm.storeInfo, "label-width": "130px" }
          },
          [
            _c("el-form-item", { attrs: { label: "头图", required: "" } }, [
              _c("div", [
                _c(
                  "label",
                  {
                    staticClass: "el-upload el-upload--picture-card",
                    attrs: { for: "brand-logo" }
                  },
                  [
                    _c("input", {
                      ref: "brandlogo",
                      attrs: {
                        type: "file",
                        hidden: "",
                        id: "brand-logo",
                        "data-type": "brandLogo",
                        name: "upload",
                        accept: "image/*"
                      },
                      on: { change: _vm.uploadImagenew }
                    }),
                    _vm.storeInfo.logo
                      ? _c("img", {
                          staticStyle: { width: "142px", height: "143px" },
                          attrs: { src: _vm.storeInfo.logo }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ]),
              _c("span", { staticClass: "ml10 form_tips" }, [
                _vm._v("建议宽高比16:9，支持JPG，PNG，JPEG格式")
              ])
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "滚动公告", required: "" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入滚动公告 ",
                    maxlength: "50",
                    type: "textarea",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.storeInfo.notice,
                    callback: function($$v) {
                      _vm.$set(_vm.storeInfo, "notice", $$v)
                    },
                    expression: "storeInfo.notice"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "店面信息", required: "" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.storeInfo.showType,
                      callback: function($$v) {
                        _vm.$set(_vm.storeInfo, "showType", $$v)
                      },
                      expression: "storeInfo.showType"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: "all" } }, [
                      _vm._v("默认展开所有店面信息")
                    ]),
                    _c("el-radio", { attrs: { label: "one" } }, [
                      _vm._v("只展开第一个店面信息，其余店面信息需手动展开查看")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "province",
                attrs: { label: "品牌商排序", required: "" }
              },
              [_c("p", [_vm._v("按照品牌商排序值，全局显示所有品牌商")])]
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitPageShop }
                  },
                  [_vm._v("保 存")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }