<template>
  <div>
    <!-- 自定义模块 -->
    <div class="disflex flexdr" v-show="plainType == 'set'">
      <div>
        <div class="phone-previvew mr20 bor1d pr br4">
          <img
            src="https://j.weizan.cn/livecontent/img/Addquestionnaire/header.jpg"
            alt
            class="w100p phone-header"
          />

          <!-- 自定义模块预览区 -->
          <div class="set-preview bgeee pr" ref="modulesView">
            <draggable @change="modulesSortEnd" v-model="modules" class="w100p">
              <div
                class="modules-item ofh pr"
                @click="getModule(j, v.type)"
                :class="{
                  act: moduleIndex === j,
                  bgfff: v.type!==2,
                  bgeee: v.type===2
                }"
                v-for="(v, j) in modules"
                :key="j"
                :type="v.type"
              >
                <i class="el-icon-delete pa remove-modules" @click.stop="removeModule(j)"></i>
                <!-- 优惠券头部 -->
                <!-- <div
                  class="bor4 ofh coupon-header bor1d bgfff br4 mb10"
                  v-if="v.type === 2 && v.cover"
                >
                  <img :src="v.cover" class="w100p" style="height: 220px;" />
                  <p v-if="v.timeRanges" class="h30 lh30 pl10 pr10 boxS cred fb">
                    <span>领券倒计时</span>
                    <span>
                      <i class="time-item-ml">{{ v.timeRanges.days }}</i> 天
                      <i class="time-item-ml">{{ v.timeRanges.hours }}</i> 时
                      <i class="time-item-ml">{{ v.timeRanges.minutes }}</i> 分
                      <i class="time-item-ml">{{ v.timeRanges.seconds }}</i> 秒
                    </span>
                  </p>
                </div>-->
                <!-- 秒杀商品 -->
                <!-- <div
                  v-if="v.type === 3 && v.timeRanges"
                  class="disflex seckill-time flexdr justifysb h30 lh30 pl10 pr10 boxS"
                >
                  <div class="cred fb fz4">限时活动秒杀价</div>
                  <div class="disflex flexdr">
                    <span class="mr5">距{{v.begging?'结束':'开始'}}</span>
                    <span class="seckill-item">
                      <i
                        style="width: auto;"
                        class="inlineb pl5 pr5"
                        v-if="v.timeRanges.days !== undefined"
                      >{{v.timeRanges.days}}天</i>
                      <i v-if="v.timeRanges.hours !== undefined">{{v.timeRanges.hours}}</i>
                      <span v-if="v.timeRanges.minutes !== undefined" class="c34 ml5">:</span>
                      <i v-if="v.timeRanges.minutes !== undefined">{{v.timeRanges.minutes}}</i>
                      <span v-if="v.timeRanges.seconds !== undefined" class="c34 ml5">:</span>
                      <i v-if="v.timeRanges.seconds !== undefined">{{v.timeRanges.seconds}}</i>
                    </span>
                  </div>
                </div>-->
                <!-- 公众号引流 -->
                <div
                  v-if="v.type === 7"
                  :style="{height: v.cover ? 'auto':'130px'}"
                  class="video-mul w100p pr ofh"
                >
                  <img v-show="v.cover" style="height: auto;" :src="v.cover" class="w100p br4" />
                  <p v-show="!v.cover" class="mt20 fz14 tc">请上传图片</p>
                </div>
                <!-- 卡券 -->
                <div
                  v-if="v.type === 2"
                  :style="{height: v.cover ? 'auto':'130px'}"
                  class="video-mul w100p pr ofh"
                >
                  <img v-show="v.cover" style="height: auto;" :src="v.cover" class="w100p br4" />
                  <p v-show="!v.cover" class="mt20 fz14 tc">请上传图片</p>
                </div>
                <!-- 秒杀活动 -->
                <div
                  v-if="v.type === 3"
                  :style="{height: v.cover ? 'auto':'130px'}"
                  class="video-mul w100p pr ofh"
                >
                  <img v-show="v.cover" style="height: auto;" :src="v.cover" class="w100p br4" />
                  <p v-show="!v.cover" class="mt20 fz14 tc">请上传图片</p>
                </div>
                <!-- 集赞活动 -->
                <div
                  v-if="v.type === 6"
                  :style="{height: v.cover ? 'auto':'130px'}"
                  class="video-mul w100p pr ofh"
                >
                  <img v-show="v.cover" style="height: auto;" :src="v.cover" class="w100p br4" />
                  <p v-show="!v.cover" class="mt20 fz14 tc">请上传图片</p>
                </div>
                <div
                  :data-index="i"
                  class="pr mul-item-i fl"
                  v-for="(k, i) in v.content"
                  :class="[v.type !== 0 || k.style ? 'w100p' : 'w50p']"
                  :key="i"
                >
                  <!-- 图片 -->
                  <div v-if="v.type === 0" class="img-item-ml">
                    <img :src="k.src" alt />
                  </div>
                  <!-- 视频 -->
                  <div
                    v-if="v.type === 1"
                    class="video-mul w100p pr"
                    :style="'background-image: url(' + v.cover + ')'"
                  >
                    <img class="play-icon-ml pa p-cc" src="https://j.weizan.cn/zhibo/img/play.png" />
                  </div>
                  <!-- 优惠券 -->
                  <!-- <div
                    v-if="v.type === 2"
                    class="coupon-ml disflex flexdr justifysb w100p bgfff br4 p5 boxS mb10"
                  >
                    <div class="disflex flexdc coupon-item-mine flex2">
                      <div class="coupon-til-tip disflex flexdc">
                        <p class="c34 fz14 ellipsis2 w100p pt5 pb5 fb">{{ k.CouponName }}</p>
                        <p class="h30 lh30">
                          <span class="cblue fz12">店面转单付款时抵扣</span>
                        </p>
                      </div>
                      <p class="h30 lh30 fz14 cae ellipsis coupon-description">{{ k.Description }}</p>
                    </div>
                    <div class="disflex flexdc justifysa alignic tc flex1">
                      <span class="cred">最高免减</span>
                      <span class="fz20 fb cred">￥{{ k.CouponMoney }}</span>
                      <button
                        :disabled="k.isOverTime"
                        class="coupon-item-get-btn fz12"
                      >{{ k.isOverTime ? "已过期" : "立即领取" }}</button>
                    </div>
                  </div>-->
                  <!-- 商品 -->
                  <!-- <div
                    v-if="v.type === 3"
                    class="preview-goods goods-ml w100p mb10 p5 disflex flexdr"
                    :key="k.id"
                  >
                    <img v-if="k.type == 'image'" :src="k.src" class="w100p" />
                    <template v-else>
                      <img class="imgurl br4" width="100" height="100" :src="k.imgurl" />
                      <div class="disflex flexdc justifysb pl10 pr10 boxS flex1">
                        <p style="width: 223px;" class="fz14 fb ellipsis">{{k.name}}</p>
                        <img class="brand-logo" width="59" height="29" :src="k.brandLogo" />
                        <p class="disflex flexr justifysb h30 lh30">
                          <span>
                            <i class="cred fz14 fb">￥{{k.originalPriceStr}}</i>
                            <i class="cae fz12 ml5">￥{{k.priceStr}}</i>
                          </span>
                          <span class="seckill-btn">立即秒杀</span>
                        </p>
                      </div>
                    </template>
                  </div>-->
                </div>
                <p v-show="!v.content.length &&(v.type===1||(v.type==0))" class="mt20 cae fz14 tc">
                  请设置{{
                  v.type === 0 ? "图片" : v.type === 1 ? "视频" : v.type === 3 ? "商品": v.type === 5 ? "优惠券礼包" : "优惠券"
                  }}内容
                </p>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="si-setting boxS p20 bor1d br4" style="height: auto;">
        <input
          type="file"
          @change="uploadFile"
          accept="image/*"
          data-type="customModule"
          hidden
          ref="uploadByModuleImageEl"
          id="image-module"
        />
        <input
          type="file"
          @change="uploadFile"
          accept="video/*"
          data-type="customModule"
          hidden
          ref="uploadByModuleVideoEl"
          id="video-module"
        />
        <!-- 自定义区域编辑区 -->
        <div class="top-text-tips">
          <p class="h30 lh30 fz16 fb c34 borb1e flex1 pb5">选择组件</p>
          <div class="mt10 mb10 cae fz14 auto-tips">提示：点击下方组件添加至自定义区域</div>
          <div class="set-options bottom-fiexd-btn disflex flexwrap pb20">
            <el-button
              class="m10"
              @click="pushModule(0)"
              :type="moduleType === 0 ? 'primary' : ''"
            >+ 添加图片({{modulesUseTimes.image}}/10)</el-button>
            <el-button
              class="m10"
              @click="pushModule(1)"
              :type="moduleType === 1 ? 'primary' : ''"
            >+ 添加视频({{modulesUseTimes.video}}/10)</el-button>
            <el-button
              class="m10"
              @click="pushModule(2)"
              :type="moduleType === 2 ? 'primary' : ''"
            >+ 添加卡券({{modulesUseTimes.coupon}}/1)</el-button>
            <el-button
              class="m10"
              @click="pushModule(3)"
              :type="moduleType === 3 ? 'primary' : ''"
            >+ 添加秒杀活动({{modulesUseTimes.goods}}/1)</el-button>
            <el-button
              class="m10"
              @click="pushModule(6)"
              :type="moduleType === 6 ? 'primary' : ''"
            >+ 添加集赞({{modulesUseTimes.like}}/1)</el-button>
            <el-button
              class="m10"
              @click="pushModule(7)"
              :type="moduleType === 7 ? 'primary' : ''"
            >+ 公众号引流({{modulesUseTimes.drainage}}/1)</el-button>
          </div>
        </div>

        <div class="top-text-tips">
          <div v-show="moduleType === 0 || moduleType === 1" class="mt20 mb20 auto-tips fz14">
            <p class="c34 mb10">操作步骤：</p>
            <p class="cae">1.选中左侧组件模块</p>
            <p class="cae">2.于下方设置内容样式</p>
          </div>
          <div v-show="moduleType >=2" class="mt20 mb20 auto-tips fz14">
            <p class="c34 mb10">操作步骤：</p>
            <p class="cae">1.通过左侧预览图视窗可以实现拖拽改变组件位置</p>
            <p class="cae">
              2.如需修改组件内容信息，请前往【营销设置】
              <!-- <a
                target="_block"
                class="ml10 cblue"
                :href="'/admin?zbid='+zbid+'#/homelive/seckillModel/0'"
              >新建秒杀活动</a>-->
            </p>
          </div>
        </div>
        <div v-show="modules.length">
          <!-- 图片 -->
          <div v-show="moduleType === 0" class="model-setting-plain">
            <div class="edite-item mb10">
              <p class="fz14 c34 fb h30 lh30 mb10">
                <i class="cred">*</i>图片样式
              </p>
              <el-radio-group
                v-if="modules[moduleIndex]"
                v-model="modules[moduleIndex].cover"
                class="pl10"
                @change="updateImageStyle"
              >
                <el-radio :label="1" border>平铺</el-radio>
                <el-radio :label="0" border>大小图</el-radio>
              </el-radio-group>
            </div>
            <div class="edite-item mb10">
              <p class="fz14 c34 fb h30 lh30 mb10">
                <i class="cred">*</i>添加图片
                <i class="fz12 cae">（ 建议宽高比16:9，支持JPG，PNG，JPEG格式，最大不超过4MB ）</i>
              </p>
              <div class="disflex flexdr mb10">
                <el-button
                  class="mr10 imgupload"
                  plain
                  icon="el-icon-upload"
                  @click="uploadByModuleImage"
                >本地上传</el-button>
              </div>
            </div>
            <div class="edite-item mb10 edite-item-image" v-if="modules[moduleIndex]">
              <draggable
                @change="imageModuleChange"
                v-model="modules[moduleIndex].content"
                class="disflex flexwrap"
              >
                <div
                  v-for="(k, i) in modules[moduleIndex].content"
                  :key="i"
                  class="pr m10 my-image-list-model"
                >
                  <i @click.stop="removeModuleItem(i, 0)" class="pa cfff cp p10 el-icon-delete"></i>
                  <img :src="k.src" class="edit-item-image bor1d br4" />
                  <input placeholder="请输入跳转链接" class="el-input__inner mt10" v-model="k.value" />
                </div>
              </draggable>
            </div>
            <div class="disflex p20 boxS" v-show="plainType === 'set'">
              <!-- @click="doneEdit" -->
              <el-button
                @click="savePage"
                :loading="btnloading"
                style="width: 375px;"
                type="primary"
              >保存设置</el-button>
            </div>
          </div>
          <!-- 视频 -->
          <div v-show="moduleType === 1" class="model-setting-plain">
            <div class="edite-item mb10">
              <p class="fz14 c34 fb h30 lh30 mb10">
                <i class="cred">*</i>视频
                <i class="fz12 cae">（ 建议视频宽高比16:9，支持MP4格式，最大不超过100M ）</i>
              </p>
              <div class="disflex flexdr">
                <el-button
                  class="mr10"
                  plain
                  icon="el-icon-upload"
                  @click="$refs.uploadByModuleVideoEl.click()"
                >本地上传</el-button>
              </div>
              <div class="disflex mt10" v-show="editModuleItem.src">
                <div style="width: 475px; height: 266px !important;">
                  <video controls preload="auto" class="video-js vjs-big-play-centered"  id="preview-video"></video>
                </div>
              </div>
            </div>
            <div class="edite-item mb10">
              <p class="fz14 c34 fb h30 lh30 mb10">
                <i class="cred">*</i>封面图
                <i class="fz12 cae">（ 建议封面图宽高比16:9 ）</i>
              </p>
              <div class="disflex flexdr">
                <el-button
                  class="mr10 imgupload"
                  plain
                  icon="el-icon-upload"
                  @click="$refs.uploadByModuleImageEl.click()"
                >本地上传</el-button>
              </div>
              <div class="disflex mt10" v-show="editModuleItem.cover">
                <img
                  style="width: 375px;height: 210px;object-fit: cover;"
                  :src="editModuleItem.cover"
                />
              </div>
            </div>
            <el-button
              @click="pushVideoInModule"
              :loading="btnloading"
              style="width: 375px;"
              class="mt10 mb20"
              type="primary"
            >保存设置</el-button>
          </div>
          <!-- 卡券 -->
          <div v-show="moduleType === 2" class="model-setting-plain">
            <div class="edite-item mb10">
              <p class="fz14 c34 fb h30 lh30 mb10">
                <i class="cred">*</i>展示样式
              </p>
              <el-radio-group
                v-if="modules[moduleIndex]"
                v-model="modules[moduleIndex].showType"
                class="pl10"
                @change="updateCouponStyle"
              >
                <el-radio :label="1" border>卡包</el-radio>
                <el-radio :label="2" border>列表</el-radio>
              </el-radio-group>
            </div>
            <!-- @click="pushCouponInModule" -->
            <el-button
              @click="savePage"
              :loading="btnloading"
              style="width: 375px;"
              class="mt10 mb20"
              type="primary"
            >保存设置</el-button>
          </div>
          <!-- 秒杀 -->
          <div v-show="moduleType === 3" class="model-setting-plain">
            <div class="mt20">
              <!-- @click="doneEdit" -->
              <el-button
                @click="savePage"
                :loading="btnloading"
                style="width: 375px;"
                type="primary"
              >保存设置</el-button>
            </div>
          </div>

          <!-- 集赞 -->
          <div v-show="moduleType === 6" class="model-setting-plain">
            <div class="mt20">
              <!-- @click="doneEdit" -->
              <el-button
                @click="savePage"
                :loading="btnloading"
                style="width: 375px;"
                type="primary"
              >保存设置</el-button>
            </div>
          </div>

          <!-- 公众号引流 -->
          <div v-show="moduleType === 7" v-if="modules[moduleIndex]" class="model-setting-plain">
            <div class="mt20">
              <!-- @click="doneEdit" -->
              <el-button
                @click="savePage"
                :loading="btnloading"
                style="width: 375px;"
                type="primary"
              >保存修改</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import videojs from "video.js";
import VDistpicker from "v-distpicker";

import draggable from "vuedraggable";

import VzMaterial from "@/components/Homelive/components/vzmaterial.vue";
import VzCoupon from "@/components/Homelive/components/vzcoupon.vue";
import SeckillActivity from "@/components/Homelive/components/seckillActivity.vue";
import HomeLiveTopics from "@/components/Homelive/components/homeLiveTopics.vue";
import CardBagList from "@/components/Homelive/components/cardBagList.vue";
import ScratchCardModel from "./scratchCardModel.vue";
import { timeStringToDate } from "@/utils/utils.js";
import { clone, formatTime, getCountDownObjByStr } from "@/utils/utils.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";

import {
  GetHomeSeckillActivity,
  SaveHomeLiveInfo
} from "@/utils/https/HomeLive/preheat.js";

import {
  HandleCouponBag,
  GetBagCouponList
} from "@/utils/https/HomeLive/cardbag.js";

// 模块类型
var MODULE_TYPES = {
  IMAGE: 0, //图片
  VIDEO: 1, //视频
  COUPON: 2, //卡券
  GOODS: 3, //秒杀活动
  LIKE: 6, //集赞
  DRAINAGE: 7, //引流
  CARDBAG: 7, //

  Image: 0,
  Video: 1,
  Card: 2, // 卡券
  SecondsKill: 3, //秒杀
  Like: 4, // 集赞
  Drainage: 5 //引流
};
export default {
  name: "preheat",
  components: {
    VDistpicker,
    draggable,
    VzMaterial,
    VzCoupon,
    CardBagList,
    HomeLiveTopics,
    SeckillActivity,
    ScratchCardModel
  },
  props: {
    inittab: {
      type: [Number, String],
      default: 0
    },
    zbid: {
      type: [Number, String],
      default: 0
    },
    tpid: {
      type: [Number, String],
      default: 0
    },
    activityID: {
      type: [Number, String],
      default: 0
    },
    // pageinfo: {
    //   type: Object,
    //   default: ()=>({})
    // },
    indexinfo: {
      type: Object,
      default: () => ({
        applyLotteryResult: null,
        banner:
          "https://i2.vzan.cc/upload/image/png/20200710/c3229ace339b4d61b62095d0eb9b327b.png",
        brandids: "",
        city: "",
        citys: "",
        couponids: "",
        coupons: null,
        cpActivity: null,
        customModule: "",
        foreshowName: "12",
        formId: 1051,
        homeSeckillActivity: null,
        homeliveCouponBag: {},
        id: 1080,
        isOpenLiveInlet: true,
        isOpenService: true,
        liveInletType: 1,
        province: "",
        serviceBtnText: "",
        serviceUrl: "",
        setting: {
          JoinNum: 0,
          OpenBrandStore: true,
          OpenJoin: false,
          OpenShare: false,
          OpenShowStats: true,
          OpenSignKeep: true,
          OpenVeriPhone: false,
          OpenView: false,
          ShareNum: 0,
          SignKeepContentList: [
            {
              Switch: true,
              Title: "赚钱不易，且行且珍惜",
              SecondTitle: "劝君莫放弃，一次相遇，省你万元现金",
              Icon:
                "https://j.weizan.cn/zhibo/livecontent/homelive/img/icon-close-one.png",
              SecondTitle: "劝君莫放弃，一次相遇，省你万元现金",
              Switch: true,
              Title: "赚钱不易，且行且珍惜"
            }
          ],
          SignTitle: "免费报名",
          ViewNum: 0
        },
        starttime: "2020-07-10 12:22:04",
        storeSetting: { banner: "", notice: "", unfoldType: 0 },
        title: "婷姐说可以随便输",
        topicId: 20951784,
        topicTag: 0,
        zbid: 622194454
      })
    },
    applylotteryresult: {
      type: Object,
      default: () => ({})
    },
    cardbag: {
      type: Object,
      default: () => ({})
    },
    coupons: {
      type: Array,
      default: () => []
    },
    customModule: {
      type: Array,
      default: () => []
    },
    topictag: {
      type: [Number, String],
      default: 0
    },
    topicttitle: {
      type: String,
      default: ""
    }
  },
  data() {
    this.PreHeatVideo = [];
    this.cardbagDefaulCover =
      "https://j.weizan.cn/zhibo/livecontent/homelive/img/cardbag-defult-cover.png?v=43465168521035";
    this.seckillCover =
      "https://j.weizan.cn/youxiang/admin/seckill.jpg?v=43465168521035";
    this.jizanCover =
      "https://j.weizan.cn/youxiang/admin/jizan.jpg?v=43465168521035";
    this.gongzhonghaoCover =
      "https://j.weizan.cn/youxiang/admin/gongzhonghao.jpg?v=43465168521035";
    this.kaquanCover =
      "https://j.weizan.cn/youxiang/admin/kaquan.jpg?v=43465168521035";
    this.cardlistCover =
      "https://j.weizan.cn/youxiang/admin/cardlist.jpg?v=43465168521035";
    this.cardbagCover =
      "https://j.weizan.cn/youxiang/admin/cardbag.jpg?v=43465168521035";
    return {
      nodata: "",
      pageinfo: {},
      plainType: "set",
      /* 基础信息 */
      isloading: false,
      /* 自定义模块 */
      editModuleItem: {},
      // 各模块使用记录
      modulesUseTimes: {
        image: 1,
        video: 0,
        coupon: 0,
        goods: 0,
        like: 0,
        drainage: 0,
        cardbag: 0
      },
      // 模块列表
      modules: [],
      moduleIndex: 0,
      isNewModule: true,
      // 0 image 1 video 2 coupon
      moduleType: 0,
      // 0 大小图 1 平铺
      imageStyleType: 1,
      // 素材框
      showMaterial: false,
      isSelImageMaterial: true,
      materialType: 3,
      maxNumber: 6,
      // 优惠券框
      showCoupon: false,
      // 已选优惠券列表
      hasCouponlist: [],
      btnloading: false,
      bindDateRanger: [],
      // 0 未开始 1 进行中 2 结束
      couponInTime: 0,

      // 打开家具话题列表
      showTopicList: false,
      // 新增所选的话题
      mytopic: {
        id: 0
      },
      paramstopic: undefined,

      // 秒杀活动
      showSeckil: false,
      myAct: {
        id: 0
      },
      // 挽留窗口默认配置
      defaultSignKeepList: [
        {
          Title: "赚钱不易，且行且珍惜",
          SecondTitle: "劝君莫放弃，一次相遇，省你万元现金",
          Icon:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/icon-close-one.png",
          Switch: true,
          index: 0
        },
        {
          Title: "比实体店低30%-70%",
          SecondTitle: "本次优惠，史无前例，工厂老总签售全年全网最低价",
          Icon:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/icon-close-two.png",
          Switch: true,
          index: 1
        },
        {
          Title: "真心不想让您错过这次优惠",
          SecondTitle: "此次优惠让利空前",
          Icon:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/icon-close-three.png",
          Switch: true,
          index: 2
        }
      ],
      /* 报名有礼 */
      scratchModel: {},
      // 优惠券礼包
      myCardBag: {
        Id: 0,
        Status: 0,
        CouponList: []
      },
      showCardBagList: false,
    };
  },
  filters: {
    //0失效，1未开始，2进行中，3已经结束
    activityState(timeStatus) {
      return timeStatus == 0
        ? "失效"
        : timeStatus == 1
        ? "未开始"
        : timeStatus == 2
        ? "进行中"
        : "已经结束";
    }
  },
  methods: {
    /*****  优化礼包 start  *****/
    getStartCardbag() {
      window.open(
        "https://live.vzan.com/zbhelpcenter.html?ArticleID=430&TabId=1",
        "_block"
      );
    },
    /**
     * 获取卡包下面的优惠券信息列表
     * @return void
     */
    getCardBagCouponList() {
      GetBagCouponList({
        zbid: this.zbid,
        couponBagId: this.myCardBag.Id
      })
        .then(res => {
          this.myCardBag.CouponList = res.data;
          this.showCardBagList = false;
        })
        .catch(errorMsg => {
          this.$message.error(errorMsg);
        });
    },
    /**
     * 编辑数据
     * @param {Number} status 1开启，2结束，-1删除, -2编辑
     * @param {Number} couponBagId 卡包id
     * @return void
     */
    handleCardBag(status, couponBagId) {
      HandleCouponBag({
        zbid: window.zbid,
        couponBagId,
        status
      })
        .then(res => {
          this.$message.success(res.Msg || "操作成功");
          this.myCardBag.Status = status;
          this.$emit("updatecardbagstatus", status);
        })
        .catch(() => {
          this.$message.error("操作失败");
        });
    },
    // 获取卡包的状态中文码
    getCardBagStatusStr(value) {
      let str = "未开启";
      switch (value) {
        case 1:
          str = "进行中";
          break;
        case 2:
          str = "已结束";
          break;
        case -1:
          str = "已删除";
          break;
      }
      return str;
    },
    // 卡包活动，切换封面选项
    cardbagCoverChange(value) {
      if (value) {
        this.modules[this.moduleIndex].cover = "";
      } else {
        this.modules[this.moduleIndex].cover = this.cardbagDefaulCover;
      }
    },
    /*****  优化礼包 end  *****/

    // 删除报名活动
    removeSginUp() {
      SaveHomeLiveInfo({
        topicId: this.indexinfo.topicid,
        zbid: this.indexinfo.zbid,
        opertype: "applylottery",
        Id: this.indexinfo.id,
        ApplyLotteryStr: null
      })
        .then(res => {
          this.scratchModel = {
            Id: 0,
            Zbid: this.zbid,
            Topicid: this.tpid,
            Activityendtime: "", // 活动结束时间
            Redeemstarttime: "", //兑奖开始时间
            Redeemendtime: "", // 兑奖结束时间
            Name: "",
            Detail: "",
            Virtualwin: 0, // 虚拟中奖
            Restrictapy: 0, // 是否开启限制
            Restric: "", // 限制地区
            ComplimentaryRelationList: []
          };
          this.modulesUseTimes.scratchCard -= 1;
          if (this.modules[0]) {
            this.getModule(0, this.modules[0].type);
          } else {
            this.moduleType = -1;
          }
          this.$message.success(res.Msg);
        })
        .catch(errorMsg => {
          this.$message.error(errorMsg);
        });
    },
    // 取消报名有礼模块编辑, 恢复选择自定义模块
    cancelScratchModel() {
      if (this.modules.length) {
        this.getModule(0, this.modules[0].type);
      } else {
        this.moduleType = 0;
      }
    },

    /*****  挽留窗口 start  *****/

    // 单个控制 开启或关闭 挽留窗口
    ctrlSigleKeepItem() {
      const openlist = this.indexinfo.setting.SignKeepContentList.filter(
        el => el.Switch
      );
      // 三个全开/全关 同步控制总开关
      if (openlist.length == 0) {
        this.indexinfo.setting.OpenSignKeep = false;
      } else {
        this.indexinfo.setting.OpenSignKeep = true;
      }
    },
    // 控制挽留窗口总体开关
    ctrlSignKeep(isopen) {
      this.indexinfo.setting.SignKeepContentList = this.indexinfo.setting.SignKeepContentList.map(
        el => {
          el.Switch = isopen;
          return el;
        }
      );
    },
    // 单个恢复默认数据
    getDefaultItem(index) {
      this.$confirm(
        "恢复预设信息，当前已编辑的信息将被清空，确定恢复预设信息吗？",
        "提示"
      ).then(() => {
        var i = this.indexinfo.setting.SignKeepContentList[index].index;
        if (!(i >= 0)) {
          i = index;
        }
        this.$set(
          this.indexinfo.setting.SignKeepContentList,
          index,
          clone(this.defaultSignKeepList[i])
        );
      });
    },
    // 设置默认的挽留窗口数据
    getDefaultSignKeepList() {
      var vhis = this;
      this.$confirm(
        "恢复全部预设信息，已编辑的信息将被全部清空，确定恢复全部预设信息吗？",
        "提示"
      ).then(() => {
        for (
          let index = 0;
          index < vhis.indexinfo.setting.SignKeepContentList.length;
          index++
        ) {
          vhis.$set(
            vhis.indexinfo.setting.SignKeepContentList,
            index,
            clone(vhis.defaultSignKeepList[index])
          );
        }

        /* vhis.indexinfo.setting.SignKeepContentList = clone(
          vhis.defaultSignKeepList
        ); */
      });
    },
    /*****  挽留窗口 end  *****/

    // 编辑活动页
    gotoEdite() {
      this.doneEdit(() => {
        this.$router.push(`/homelive/seckillModel/${this.myAct.id}`);
      });
    },
    // 选择秒杀互动后 更新预览信息
    updateSeckillModel(index) {
      const itemIndex = index || this.moduleIndex;
      const activityObj = this.myAct.customModule
        ? JSON.parse(this.myAct.customModule)
        : [];
      let content = [];
      const cusids = [];
      // 秒杀活动-格式自定义模块
      for (let index = 0; index < activityObj.length; index++) {
        const el = activityObj[index];
        if (el.type == "goods") {
          const item = this.myAct.seckillProducts.find(gel => gel.id == el.id);
          if (item) {
            cusids.push(item.id);
            content.push(item);
          }
        } else {
          content.push(el);
        }
      }
      // 校验新添加的商品，但未保存商品顺序时，添加到自定义模块的尾部
      if (cusids.length) {
        // 找到自定义模块之外的商品
        const lessGoods = this.myAct.seckillProducts.filter(
          lg => !cusids.includes(lg.id)
        );
        if (lessGoods.length) {
          content = content.concat(
            lessGoods.map(gel => {
              gel.type = "goods";
              return gel;
            })
          );
        }
      }
      // 更新预热页模块里的信息
      const begging = this.myAct.timeStatus == 2;
      this.$set(this.modules, itemIndex, {
        timeRanges: getCountDownObjByStr(
          formatTime(this.myAct[begging ? "endTime" : "startTime"], 1),
          new Date()
        ),
        content,
        begging,
        type: MODULE_TYPES.GOODS,
        cover: this.myAct.id
      });
    },
    // 获取秒杀活动
    getHomeLiveSeckill(item) {
      this.showSeckil = false;
      GetHomeSeckillActivity({
        zbid: this.zbid,
        id: item.id
      })
        .then(res => {
          this.myAct = res.data;
          this.$nextTick(() => {
            this.updateSeckillModel(item.index);
          });
        })
        .catch(errorMsg => {
          this.$message.error(errorMsg);
        });
    },
    // 新建预热页绑定话题
    getHomeLiveTopic(topicItem) {
      this.mytopic = topicItem;
      this.indexinfo.topicid = topicItem.id;
      this.showTopicList = false;
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.baseInfo.poster = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传文件
    async uploadFile(event) {
      let loading = null;
      if (event.target.id === "video-module") {
        loading = this.$loading({
          lock: true,
          text: "上传中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      }
      try {
        console.log(event);
        if (event.target.id === "video-module") {
          console.log(111);
          var imageData = await this.uploadvideo.uploadFile(event);
        console.log(imageData)
        } else {
          var imageData = await this.upload.uploadFile(event,{maxSize: 4});
        }
        console.log(imageData)
        loading && loading.close();
        if (imageData.fromType === "serviceUrl") {
          this.indexinfo.serviceUrl = imageData.src;
        } else if (imageData.fromType === "banner") {
          this.indexinfo.banner = imageData.src;
        } else if (imageData.fromType === "customModule") {
          // path, file, info, extrainfo
          this.addImageByModel(imageData.src, imageData.fileType);
        } else if (imageData.fromType.includes("signkeep_")) {
          var index = imageData.fromType.split("_")[1];
          this.indexinfo.setting.SignKeepContentList[index].Icon =
            imageData.src;
          this.$set(
            this.indexinfo.setting.SignKeepContentList,
            index,
            this.indexinfo.setting.SignKeepContentList[index]
          );
        }
      } catch (errorMsg) {
        loading && loading.close();
        this.$message.error(errorMsg.message);
      }
    },
    // 修改优惠券的时间
    getDateRanges(value) {
      if (this.hasCouponlist.length) {
        // var oldTimes = this.bindDateRanger;
        this.$alert("修改时间后 已选优惠券将被清空，是否继续操作？", "提示", {
          showClose: false,
          confirmButtonText: "继续",
          showCancelButton: true
        })
          .then(() => {
            // 修改时间范围清空已选优惠券
            this.editModuleItem.dateRanges = value;
            this.modules[this.moduleIndex].dateRanges = value;
            this.hasCouponlist = [];
            this.modules[this.moduleIndex].content = [];
            this.editModuleItem.content = [];
          })
          .catch(() => {
            if (this.editModuleItem.dateRanges.length) {
              this.bindDateRanger = [
                this.editModuleItem.dateRanges[0],
                this.editModuleItem.dateRanges[1]
              ];
            }
          });
      } else {
        this.editModuleItem.dateRanges = value;
        this.modules[this.moduleIndex].dateRanges = value;
      }
    },
    /* 自定义信息 start */
    imageModuleChange() {
      this.updateImageStyle(this.modules[this.moduleIndex].cover);
    },
    // 移除选择的优惠券
    removeCouponItem(i) {
      var item = this.editModuleItem.content.splice(i, 1);
      if (item[0]) {
        var ids = item[0].Id;
        for (var is = 0; is < this.hasCouponlist.length; is++) {
          var el = this.hasCouponlist[is];
          if (el === ids) {
            this.hasCouponlist.splice(is, 1);
            break;
          }
        }
      }
    },
    /**
     * 打开优惠券弹窗
     * @param {Array} list 优惠券信息列表
     * @param {Array} idsitem 优化ids列表,已选总列表
     * @return void
     */
    getCoupons(list, idsitem) {
      console.log(list, idsitem);
      var listss = [];
      // 合并旧已存在的 交集
      listss = this.editModuleItem.content.filter(el =>
        idsitem.includes(el.Id)
      );
      // 合并新选的 并集
      list.forEach(nel => {
        var size = listss.length;
        if (size <= 50) {
          // 当前项在选择列表中
          const idi = idsitem.findIndex(elid => elid === nel.Id);
          // 当前项不在已选列表中
          const lidi = listss.findIndex(lid => lid.Id === nel.Id);
          if (idi >= 0 && lidi === -1) {
            nel.payfee = (nel.payfee / 100).toFixed(2);
            nel.CouponMoney = (nel.CouponMoney / 100).toFixed(2);
            listss.push(nel);
          }
        } else {
          console.log("优惠券超过 50 张");
        }
      });
      this.editModuleItem.content = listss;
      this.hasCouponlist = idsitem;
      this.showCoupon = false;
    },
    // 打开优惠券
    openCoupon() {
      if (this.bindDateRanger.length) {
        this.maxNumber = 50;
        this.showCoupon = true;
      } else {
        this.$message.info("请先选择优惠券的领取时间范围！");
      }
    },
    // 优惠券模块添加数据
    pushCouponInModule() {
      if (!this.editModuleItem.dateRanges) {
        this.$message.info("请设置正确的时间范围");
        return;
      }
      if (
        this.editModuleItem.dateRanges[0] > this.editModuleItem.dateRanges[1]
      ) {
        this.$message.info("请设置正确的时间范围");
        return;
      }

      if (!this.editModuleItem.cover) {
        this.$message.info("请设置正确的优惠券头图");
        return;
      }

      if (!this.editModuleItem.content.length) {
        this.$message.info("至少需要添加一张优惠券哦");
        return;
      }

      this.$set(this.modules, this.moduleIndex, {
        type: 2,
        timeRanges: getCountDownObjByStr(
          formatTime(this.editModuleItem.dateRanges[1], 1),
          new Date()
        ),
        dateRanges: [
          formatTime(this.editModuleItem.dateRanges[0], 1),
          formatTime(this.editModuleItem.dateRanges[1], 1)
        ],
        cover: this.editModuleItem.cover,
        content: clone(this.editModuleItem.content)
      });
      this.isNewModule = false;

      this.$nextTick(() => {
        this.doneEdit();
      });
    },
    // 视频模块添加视频
    pushVideoInModule() {
      if (!this.editModuleItem.cover || !this.editModuleItem.src) {
        this.$message.info("请上传完整的视频信息，视频加封面");
        return;
      }
      this.$set(this.modules, this.moduleIndex, {
        type: 1,
        cover: this.editModuleItem.cover || "",
        src: this.editModuleItem.src || "",
        timeRanges: null,
        content: [{}]
      });
      this.isNewModule = false;

      this.$nextTick(() => {
        this.savePage();
      });
    },
    // 完成编辑
    doneEdit(callback) {
      var isok = true;
      for (var index = 0; index < this.modules.length; index++) {
        var el = this.modules[index];
        if (el.type === MODULE_TYPES.IMAGE && el.content.length === 0) {
          this.$message.info("请为图片模块添加图片");
          this.moduleIndex = index;
          isok = false;
          break;
        } else if (el.type === MODULE_TYPES.VIDEO && (!el.cover || !el.src)) {
          this.$message.info("请完善视频模块必填的信息！");
          this.moduleIndex = index;
          isok = false;
          break;
        } else if (
          el.type === MODULE_TYPES.COUPON &&
          (!el.cover || !el.dateRanges.length || !el.content.length)
        ) {
          this.$message.info("请完善优惠券模块必填的信息！");
          this.moduleIndex = index;
          isok = false;
          break;
        }
      }
      if (isok) {
        var couponids = [];
        var CouponIndex = -1;
        var seckillIndex = -1;
        var cardbagIndex = -1;
        // 校验自定义设置是否完整
        for (var i = 0; i < this.modules.length; i++) {
          if (this.modules[i].type === MODULE_TYPES.COUPON) {
            couponids = this.modules[i].content.map(el => el.Id);
            CouponIndex = i;
            continue;
          } else if (this.modules[i].type === MODULE_TYPES.IMAGE) {
            for (var k = 0; k < this.modules[i].content.length; k++) {
              var value = this.modules[i].content[k].value;
              if (value.length) {
                var ishttp =
                  value.indexOf("https://") === 0 ||
                  value.indexOf("http://") === 0;
                if (!ishttp) {
                  this.$message.error(
                    "第" +
                      (i + 1) +
                      "个模块里的第" +
                      (k + 1) +
                      "张图片的跳转链接必须以https:// 或 http:// 开头"
                  );
                  this.btnloading = false;
                  return false;
                }
              }
            }
          } else if (this.modules[i].type === MODULE_TYPES.GOODS) {
            if (!this.modules[i].content.length) {
              this.$message.error("请选择要绑定的秒杀活动或删除该组件！");
              return false;
            }
            if (this.myAct.id) {
              seckillIndex = i;
              continue;
            } else {
              this.$message.error("请选择秒杀活动活动！");
              return;
            }
          } else if (this.modules[i].type === MODULE_TYPES.CARDBAG) {
            this.cardbagIndex = i;
            cardbagIndex = i;
            if (this.myCardBag.Id) {
              this.modules[i].id = this.myCardBag.Id;
            } else {
              this.$message.error("请选择活动的优惠券礼包~");
              return false;
            }
            // 校验优惠券礼包设置
            if (this.modules[i].endTime === "") {
              this.$message.error("请选择活动结束时间");
              return;
            } else {
              const nowTime = new Date();
              const endTime = timeStringToDate(this.modules[i].endTime);
              const diffTIme = endTime.getTime() - nowTime.getTime();
              const twoHour = 2 * 60 * 60 * 1000;
              const nowYear = nowTime.getFullYear();
              const fiveYear = new Date(
                formatTime(0, 1).replace(nowYear, nowYear + 5)
              );
              if (fiveYear < endTime) {
                this.$message.error("截止时间最长可选范围为当前时间开始5年内");
                return;
              }
              if (diffTIme < twoHour) {
                // 校验有效时间
                if (this.cardbag.CouponBagId === 0) {
                  this.$message.error(
                    "【优惠券礼包】 结束时间需超过当前时间2小时"
                  );
                  return;
                } else if (this.cardbag.CouponBagId > 0) {
                  // 编辑状态提示修改，允许撤销
                  const dateOld = timeStringToDate(
                    this.cardbag.EndTime
                  ).getTime();
                  const dateNew = timeStringToDate(
                    this.modules[i].endTime
                  ).getTime();
                  console.log(dateOld, dateNew);
                  if (dateOld !== dateNew) {
                    this.$confirm(
                      "【优惠券礼包】结束时间需要超过当前时间2小时",
                      "提示",
                      {
                        confirmButtonText: "知道了",
                        cancelButtonText: "撤销修改"
                      }
                    ).catch(() => {
                      this.modules[cardbagIndex].endTime = timeStringToDate(
                        this.cardbag.EndTime
                      );
                    });
                    return;
                  }
                }
              }
            }
          }
        }
        var customModule = clone(this.modules);
        // 是否存在优惠券模块
        if (CouponIndex >= 0) {
          customModule[CouponIndex].content = [];
          var itemcp = customModule[CouponIndex].dateRanges;
          if (typeof itemcp[0] == "object") {
            customModule[CouponIndex].dateRanges = [
              formatTime(itemcp[0], 1),
              formatTime(itemcp[1], 1)
            ];
          }
        }
        let HomeSeckillActivityId = null;
        // 是否选了秒杀活动
        if (seckillIndex >= 0) {
          // 简化商品图片数据
          customModule[seckillIndex].content = customModule[
            seckillIndex
          ].content.map(el => {
            if (el.type == "image") {
              return el;
            } else {
              return {
                type: "goods",
                id: el.id
              };
            }
          });
          HomeSeckillActivityId = customModule[seckillIndex].cover;
        }
        // 是否选择了 优惠券礼包
        let HomeliveCouponBagStr = "";
        let cardbagItem = {};
        if (cardbagIndex >= 0) {
          let cardbagEndTime = this.modules[cardbagIndex].endTime;
          if (typeof cardbagEndTime === "object") {
            this.modules[cardbagIndex].endTime = formatTime(cardbagEndTime, 1);
          }
          cardbagItem = {
            CouponBagId: this.myCardBag.Id,
            CoverType: customModule[cardbagIndex].coverType,
            Cover: customModule[cardbagIndex].cover,
            EndTime: this.modules[cardbagIndex].endTime
          };
          // 校验活动封面是否上传
          if (cardbagItem.CoverType === 1 && !cardbagItem.Cover) {
            this.$message.error("请上传自定义封面图片");
            return;
          }
          HomeliveCouponBagStr = JSON.stringify(cardbagItem);
        }

        this.btnloading = true;
        const postData = {
          topicId: this.indexinfo.topicid,
          zbid: this.indexinfo.zbid,
          couponids: couponids.join(","),
          customModule: JSON.stringify(customModule),
          Id: this.indexinfo.id,
          opertype: "custom",
          HomeSeckillActivityId,
          HomeliveCouponBagStr
        };
        SaveHomeLiveInfo(postData)
          .then(res => {
            this.$message.success(res.Msg || "保存成功");
            if (this.cardbag.CouponBagId === 0 && HomeliveCouponBagStr.length) {
              const cloneitem = clone(this.myCardBag);
              this.$emit("newcardbag", cloneitem);
            }
            // setLocal(`${this.indexinfo.topicid}_preheat_custom`, postData);
            if (typeof callback === "function") {
              callback && callback();
            }
            this.btnloading = false;
          })
          .catch(msg => {
            this.$message.info(msg || "保存失败!");
            this.btnloading = false;
          });
      }
    },
    // 本地上传封面/视频
    addImageByModel(path, filetype) {
      if (this.moduleType === MODULE_TYPES.IMAGE) {
        this.modules[this.moduleIndex].content.push({
          style: this.modules[this.moduleIndex].cover,
          src: path,
          value: ""
        });
        // 刷新样式
        var valuetype = this.modules[this.moduleIndex].cover;
        this.$nextTick(() => {
          this.updateImageStyle(valuetype);
        });
      } else if (this.moduleType === MODULE_TYPES.VIDEO) {
        if (filetype.indexOf("image") >= 0) {
          this.editModuleItem.cover = path;
        } else {
          console.log(path);
          let opy=JSON.parse(JSON.stringify(this.editModuleItem));
          opy.src=path;
          this.editModuleItem = opy;
          this.PreHeatVideo.src(opy.src);
          // this.PreHeatVideo.src(path);
        }
      } else if (this.moduleType === MODULE_TYPES.COUPON) {
        this.editModuleItem.cover = path;
        this.modules[this.moduleIndex].cover = path;
      } else if (this.moduleType === MODULE_TYPES.CARDBAG) {
        this.modules[this.moduleIndex].cover = path;
      }
    },
    // 图片显示方式 0 大小图 1 平铺
    updateImageStyle(value) {
      var list = this.modules[this.moduleIndex].content;
      if (value) {
        this.modules[this.moduleIndex].content = list.map(el => {
          el.style = true;
          return el;
        });
      } else {
        this.modules[this.moduleIndex].content = list.map((el, i, arr) => {
          if (arr.length === i + 1 && arr.length === 5) {
            // 5張
            el.style = true;
          } else if (arr.length == 2) {
            // 2 張
            el.style = true;
          } else {
            el.style = (i + 3) % 3 === 0;
          }
          return el;
        });
      }
    },
    // 卡券showType字段，1表示卡包，2表示优惠券列表
    updateCouponStyle(value) {
      var list = this.modules[this.moduleIndex].content;
      if (value == 1) {
        this.modules[this.moduleIndex].cover = this.cardbagCover;
      } else {
        this.modules[this.moduleIndex].cover = this.cardlistCover;
      }
    },
    // 删除模块内容 的 item
    removeModuleItem(i, type) {
      try {
        this.modules[this.moduleIndex].content.splice(i, 1);
        if (type === MODULE_TYPES.VIDEO) {
          this.reSetEdit();
        } else if (type === MODULE_TYPES.IMAGE) {
          this.updateImageStyle(this.modules[this.moduleIndex].cover);
        } else if (type === MODULE_TYPES.CARDBAG) {
          this.myCardBag = { Id: 0 };
        }
      } catch (error) {
        this.$message.error("请选择要删除的内容");
      }
    },
    // 模块排序
    modulesSortEnd(item) {
      this.moduleIndex = item.moved.newIndex;
      this.moduleType = this.modules[this.moduleIndex].type;
      this.editModuleItem = clone(this.modules[this.moduleIndex]);
    },
    // 删除模块
    removeModule(j) {
      this.moduleIndex = j || 0;
      let tips = "确定要删除该模块吗？";

      this.$confirm(tips, "信息提示").then(() => {
        var items = this.modules.splice(this.moduleIndex, 1);
        if (items[0]) {
          var item = items[0];
          // 减去模块使用次数
          if (item.type === MODULE_TYPES.VIDEO) {
            this.modulesUseTimes.video -= 1;
          } else if (item.type === MODULE_TYPES.IMAGE) {
            this.modulesUseTimes.image -= 1;
          } else if (item.type === MODULE_TYPES.COUPON) {
            this.modulesUseTimes.coupon -= 1;
            this.hasCouponlist = [];
          } else if (item.type === MODULE_TYPES.GOODS) {
            this.modulesUseTimes.goods -= 1;
          } else if (item.type === MODULE_TYPES.LIKE) {
            this.modulesUseTimes.like -= 1;
          } else if (item.type === MODULE_TYPES.DRAINAGE) {
            this.modulesUseTimes.drainage -= 1;
          }
          var len = this.modules.length;
          if (len) {
            this.moduleType = this.modules[len - 1].type;
            this.getModule(len - 1, this.moduleType);
            $(".set-preview")
              .eq(1)
              .scrollTop(
                $(".set-preview")
                  .eq(1)
                  .get(0).scrollHeight
              );
          } else {
            this.editModuleItem = {
              src: "",
              cover: "",
              content: [],
              dateRanges: [],
              timeRanges: {}
            };
          }
          // this.$nextTick(() => {
          //   this.doneEdit();
          // });
        } else {
          this.$message.info("请选择要删除的模块");
        }
      });
    },
    // 选择编辑的模块
    getModule(i, type) {
      this.moduleType = type;
      this.moduleIndex = i;
      this.isNewModule = this.modules[i].content.length === 0;
      var item = this.modules[i] || {
        type: type,
        cover: "",
        src: "",
        content: [],
        timeRanges: []
      };
      if (item) {
        this.editModuleItem = clone(item);
        if (this.editModuleItem.type == MODULE_TYPES.COUPON) {
          this.$nextTick(() => {
            if (!this.editModuleItem.content.length) {
              this.hasCouponlist = [];
            } else {
              this.hasCouponlist = this.editModuleItem.content.map(
                el => el.id || el.Id
              );
            }
          });
        }
        this.bindDateRanger = this.editModuleItem.dateRanges;
        // 视频模块加载视频
        if (item.type === MODULE_TYPES.VIDEO) {
          this.PreHeatVideo && this.PreHeatVideo.src(item.src);
        }
      }
    },
    // 重置编辑数据
    reSetEdit() {
      this.editModuleItem = {
        content: [],
        cover: "",
        timeRanges: null,
        startTime: "",
        endTime: "",
        coverType: 0
      };
      // 默认编辑状态，优惠券模块时间一个星期
      if (this.moduleType === MODULE_TYPES.COUPON) {
        this.editModuleItem.timeRanges = [
          new Date(),
          new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        ];
      }
    },
    /**
     * 添加模块
     * @param {Number} i 模块类型 (MODULE_TYPES)
     * @return void
     */

    //     // 模块类型
    // var MODULE_TYPES = {
    //   Image: 0,
    //   Video: 1,
    //   Card: 2, // 卡券
    //   SecondsKill: 3, //秒杀
    //   Like: 4, // 集赞
    //   Drainage: 5 //引流
    // };
    async pushModule(i) {
      let isok = true;
      let errorTips = "";
      // 验证模块使用次数
      this.moduleType = i;
      if (i === MODULE_TYPES.IMAGE) {
        if (this.modulesUseTimes.image >= 10) {
          errorTips = "最多可添加10个图片模块";
          isok = false;
        } else {
          this.maxNumber = 6;
          this.modulesUseTimes.image += 1;
        }
      } else if (i === MODULE_TYPES.VIDEO) {
        if (this.modulesUseTimes.video >= 10) {
          errorTips = "最多可添加10个视频模块";
          isok = false;
        } else {
          this.modulesUseTimes.video += 1;
          this.maxNumber = 1;
        }
      } else if (i === MODULE_TYPES.COUPON) {
        if (this.modulesUseTimes.coupon >= 1) {
          errorTips = "最多可添加1个卡券模块";
          isok = false;
        } else {
          var hasCoupon = await this.getCouponList();
          if (!hasCoupon) {
            errorTips = "请先前往 - 【营销设置】-【卡券设置】填写相关内容";
            isok = false;
          } else {
            this.modulesUseTimes.coupon += 1;
            this.maxNumber = 1;
          }
        }
      } else if (i === MODULE_TYPES.GOODS) {
        if (this.modulesUseTimes.goods >= 1) {
          errorTips = "最多可添加1个秒杀活动模块";
          isok = false;
        } else {
          var hasSec = await this.getSeckillProductList();
          console.log(hasSec);
          if (!hasSec) {
            errorTips = "请先前往 - 【营销设置】-【秒杀设置】填写相关内容";
            isok = false;
          } else {
            this.modulesUseTimes.goods += 1;
            this.maxNumber = 1;
          }
        }
      } else if (i === MODULE_TYPES.LIKE) {
        if (this.modulesUseTimes.like >= 1) {
          errorTips = "最多可添加1个添加集赞模块";
          isok = false;
        } else {
          var hasLike = await this.getCollectLike();
          console.log(hasLike);
          if (!hasLike) {
            errorTips = "请先前往 - 【营销设置】-【集赞设置】填写相关内容";
            isok = false;
          } else {
            console.log(2333);
            this.modulesUseTimes.like += 1;
            this.maxNumber = 1;
          }
        }
      } else if (i === MODULE_TYPES.DRAINAGE) {
        if (this.modulesUseTimes.drainage >= 1) {
          errorTips = "最多可添加1个公众号引流模块";
          isok = false;
        } else {
          var hasDrainage = await this.getPublicAccount();
          if (!hasDrainage) {
            errorTips =
              "请先前往 - 【营销设置】-【公众号引流设置】填写相关内容";
            isok = false;
          } else {
            this.modulesUseTimes.drainage += 1;
            this.maxNumber = 1;
          }
        }
      }
      // 警告提示
      if (!isok) {
        this.moduleIndex = this.modules.findIndex(el => el.type == i);
        if (this.moduleIndex >= 0) {
          this.moduleType = i;
          this.getModule(this.moduleIndex, this.moduleType);
        }
        this.$message.warning(errorTips);
        return;
      }

      // 排除报名有礼活动，报名有礼不嵌入自定义模块
      if (true) {
        const twoHour = 2 * 60 * 60 * 1000;
        var coustmData = {
          type: this.moduleType,
          id: 0,
          content: [],
          cover: i == 0 ? 1 : i === 7 ? this.gongzhonghaoCover : "",
          timeRanges: null,
          startTime: "",
          endTime: new Date(Date.now() + twoHour),
          coverType: 0
        };
        if (i === 2) {
          coustmData.cover = this.cardbagCover;
          coustmData.showType = 1;
        } else if (i === 3) {
          coustmData.cover = this.seckillCover;
          coustmData.timeRanges = [];
        } else if (i === 6) {
          coustmData.cover = this.jizanCover;
          coustmData.timeRanges = [];
        }
        // 其他模块添加到自定义区域
        this.modules.push(coustmData);
        this.bindDateRanger = [];
        this.isNewModule = true;
        this.reSetEdit();
        this.$nextTick(() => {
          this.moduleIndex = this.modules.length - 1;
          this.$refs.modulesView.scrollTop = this.$refs.modulesView.scrollHeight;
        });
      }
    },
    // 获取图片素材列表
    getMatertials(list) {
      this.showMaterial = false;
      if (list.length) {
        for (var i = 0; i < list.length; i++) {
          var el = list[i];
          var imageUrl = el.FilePath || el.src;
          if (this.moduleType === MODULE_TYPES.VIDEO) {
            if (this.materialType === 1) {
              imageUrl = imageUrl.replace("http:", "https:");
              this.editModuleItem.src = imageUrl;
              this.PreHeatVideo.src(imageUrl);
            } else if (this.materialType === 3) {
              this.editModuleItem.cover = imageUrl;
            }
          } else if (this.moduleType === MODULE_TYPES.IMAGE) {
            if (this.modules[this.moduleIndex].content.length < 6) {
              this.modules[this.moduleIndex].content.push({
                style: this.modules[this.moduleIndex].cover,
                src: imageUrl,
                value: ""
              });
              this.$nextTick(() => {
                this.updateImageStyle(this.modules[this.moduleIndex].cover);
              });
            } else {
              this.$message.info("单个图片模块最多6张图片");
              break;
            }
          } else if (this.moduleType === MODULE_TYPES.COUPON) {
            this.editModuleItem.cover = imageUrl;
            this.modules[this.moduleIndex].cover = imageUrl;
          } else if (this.moduleType === -1) {
            this.indexinfo.banner = el.src;
          }
        }
      }
    },
    // 图片模块上传图片
    uploadByModuleImage() {
      if (this.modules[this.moduleIndex].content.length >= 6) {
        this.$message.info("单个图片模块最多6张图片");
        return;
      }
      this.$refs.uploadByModuleImageEl.click();
    },
    // 打开图片素材库
    openMaterial(materialType, modulesType) {
      this.materialType = materialType;
      if (
        modulesType === MODULE_TYPES.VIDEO ||
        modulesType === MODULE_TYPES.COUPON
      ) {
        this.isSelImageMaterial = false;
        this.maxNumber = 1;
      } else if (modulesType === MODULE_TYPES.IMAGE) {
        if (this.modules[this.moduleIndex].content.length >= 6) {
          this.$message.info("单个图片模块最多6张图片");
          return;
        }
        this.isSelImageMaterial = true;
        this.maxNumber = 6 - this.modules[this.moduleIndex].content.length;
      } else if (modulesType === -1) {
        this.maxNumber = 1;
        this.moduleType = modulesType;
      }
      this.$nextTick(() => {
        this.showMaterial = true;
      });
    },

    /* 基础信息  start */

    // 切换设置
    updateSetting(value) {
      if (value !== this.plainType) {
        if (value === "set" && this.topictag == -1) {
          this.$message.error("当前绑定的话题已被删除！");
          return;
        }
        if (value === "set" && this.indexinfo.id === 0) {
          this.$message.error("请先设置基础信息");
          return;
        }
        this.plainType = value;
      }
    },
    // 提交基础信息
    onSubmit(formName) {
      if (this.isloading) return false;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.indexinfo.topicid) {
            this.$message.error("请选择预热页要关联的话题！");
            return;
          }
          // 报名按钮验证
          if (this.indexinfo.setting.SignTitle.length == 0) {
            this.$message.error("请填写报名按钮文案！");
            return;
          }
          if (this.indexinfo.setting.SignTitle.length > 5) {
            this.$message.error("报名按钮文案最多5个字！");
            return;
          }
          for (
            let index = 0;
            index < this.indexinfo.setting.SignKeepContentList.length;
            index++
          ) {
            const element = this.indexinfo.setting.SignKeepContentList[index];
            if (element.Switch) {
              if (element.Title == "") {
                this.$message.error(
                  "请输入第" + (index + 1) + "个挽留窗口的主标题"
                );
                return;
              }
              if (element.SecondTitle == "") {
                this.$message.error(
                  "请输入第" + (index + 1) + "个挽留窗口的主标题"
                );
                return;
              }
            }
          }
          this.isloading = true;
          const payloadData = clone(this.indexinfo);
          payloadData.setting = JSON.stringify(payloadData.setting);
          $.post("/liveajax/SaveHomeLiveInfo", payloadData, res => {
            if (res.isok) {
              // 新增
              if (parseInt(this.indexinfo.id) <= 0) {
                this.indexinfo.id = Number(res.code);
                this.$emit("newpage", res.code, this.indexinfo.topicid);
                this.$nextTick(() => {
                  this.$router.replace(
                    `/homelive/settingPreheat/${res.code}/${this.indexinfo.topicid}`
                  );
                });
              }
              this.$message.success("保存成功");
              this.plainType = "set";
            } else {
              this.$message.error(res.Msg);
            }
            this.isloading = false;
          });
        } else {
          //this.$message.error('请按要求填写数据');
          this.isloading = false;
          return false;
        }
      });
    },
    // 城市选择
    cityselect(data) {
      this.indexinfo.province =
        data.province.value == "全部" ? "" : data.province.value;
      this.indexinfo.city = data.city.value == "全部" ? "" : data.city.value;
      this.indexinfo.citys = "";
      if (this.indexinfo.province != "") {
        this.indexinfo.citys = this.indexinfo.province;
      }
      if (this.indexinfo.city != "") {
        this.indexinfo.citys += "," + this.indexinfo.city;
      }
    },
    changeLocation(data) {
      if (data.province.value == "全部") {
        data.province.value = "";
      }
      this.indexinfo.province = data.province.value;
      this.indexinfo.citys = data.province.value;
      if (data.city.value == "全部") {
        this.indexinfo.city = "";
      }
    },

    /**
     * 初始化自定义模块 数据格式
     * @param {Array} modulslist 模块列表
     * @param {Array} myCoupons 优化模块所选优惠券
     * @return void
     */
    formatCustomModule(modulslist, myCoupons) {
      // 默认自定义模块列表
      var myModules = [
        {
          type: 0,
          content: [],
          style: 1,
          cover: 1
        }
      ];
      // 默认编辑中的模块
      var editemoduls = {
        type: 0,
        src: "",
        cover: "",
        dateRange: [new Date(), new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)],
        timeRanges: {}
      };
      // 各模块使用记录
      var modulesUseTimes = {
        image: 1, //图片
        video: 0, //视频
        coupon: 0, //卡券
        like: 0, //集赞
        cardbag: 0,
        goods: 0, //秒杀活动
        drainage: 0 //引流
      };
      // 默认选中类型
      var moduleTypeDefaul = MODULE_TYPES.IMAGE;
      // 默认优惠券ids
      var hasCouponlist = [];
      try {
        myModules = modulslist;
        modulesUseTimes.image = 0;
        for (var i = 0; i < myModules.length; i++) {
          if (myModules[i].type === MODULE_TYPES.IMAGE) {
            // 图片块
            modulesUseTimes.image += 1;
            myModules[i].content = myModules[i].content.map(function(el) {
              el.value = el.value || "";
              return el;
            });
          } else if (myModules[i].type === MODULE_TYPES.VIDEO) {
            // 视频块
            modulesUseTimes.video += 1;
          } else if (myModules[i].type === MODULE_TYPES.COUPON) {
            // 卡券块
            modulesUseTimes.coupon += 1;
            // continue;
          } else if (myModules[i].type === MODULE_TYPES.GOODS) {
            // 秒杀活动块
            modulesUseTimes.goods += 1;
          } else if (myModules[i].type === MODULE_TYPES.LIKE) {
            // 集赞块
            modulesUseTimes.like += 1;
          } else if (myModules[i].type === MODULE_TYPES.CARDBAG) {
            // 公众号引流块
            modulesUseTimes.drainage += 1;
          }
        }
        if (myModules[0]) {
          editemoduls = clone(myModules[0]);
          moduleTypeDefaul = editemoduls.type;
        }
      } catch (error) {
        console.log(error);
        myModules = [
          {
            type: 0,
            content: [],
            style: 1,
            cover: 1
          }
        ];
      }
      // 初始化时间选择器
      this.bindDateRanger = editemoduls.dateRanges
        ? editemoduls.dateRanges
        : [];
      // 更新模块使用次数
      this.modulesUseTimes = modulesUseTimes;
      // 已选优惠券 ids
      this.hasCouponlist = hasCouponlist;
      // 默认选中的编辑模块
      this.editModuleItem = editemoduls;
      // 自定义模块列表
      this.modules = myModules;
      // 选中的模块类型
      this.moduleType = this.modules.length ? moduleTypeDefaul : -1;
      // 链接中是否带有话题id （来自话题久编辑页）
      this.paramstopic = this.$route.params.tpid;
      // 初始化活动报名有礼数据实体
      this.scratchModel = clone(this.applylotteryresult);
      if (this.scratchModel.Id) {
        this.modulesUseTimes.scratchCard = 1;
      }
      // 添加了优惠券礼包
      this.myCardBag = {
        Zbid: this.zbid,
        Id: this.cardbag.CouponBagId || 0,
        Name: this.cardbag.Name || "",
        CouponList: clone(this.cardbag.CouponList || []),
        CouponNums: this.cardbag.CouponNums || 0,
        Price: this.cardbag.Price || 0,
        PriceStr: (this.cardbag.Price || 0).toFixed(2),
        Sales: 0,
        Stock: 0,
        Surplus: 0,
        Status: this.cardbag.Status,
        StatusStr: this.getCardBagStatusStr(this.cardbag.Status)
      };
      // clone(this.cardbag);
      this.$nextTick(() => {
        // 初始化视频模块播放器实例
        this.PreHeatVideo = videojs("preview-video");
        console.log(this.PreHeatVideo)
        // 默认选中为视频模块时，创建视频模块播放器
        if (this.editModuleItem.type === MODULE_TYPES.VIDEO) {
          this.PreHeatVideo.src(this.editModuleItem.src);
        }
        // 来自url 默认选择自定义模块(报名有礼)
        if (this.inittab === 2) {
          this.plainType = "set";
          this.moduleType = 4;
        }
      });
    },
    /**
     * 首页布局设置-查看
     * @param {Array} actId 活动Id
     */
    async getPage() {
      try {
        const data = await MarketingAjax.getPage(this.activityID);
        if (data.data.customModule) {
          this.modules = JSON.parse(data.data.customModule);
        } else {
          this.modules = [];
        }
        // 初始格式化 自定义模块的数据
        this.formatCustomModule(this.modules, this.coupons);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑页面配置
    async savePage() {
      try {
        for (var index = 0; index < this.modules.length; index++) {
          var el = this.modules[index];
          if (el.type === MODULE_TYPES.IMAGE && el.content.length === 0) {
            this.$message.info("请为图片模块添加图片");
            this.moduleIndex = index;
            return;
          }
          console.log(el.src);
          console.log(el.type === MODULE_TYPES.VIDEO);
          if (el.type === MODULE_TYPES.VIDEO && !el.src && !el.cover) {
            this.$message.info("请完善视频模块必填的信息！");
            this.moduleIndex = index;
            return;
          }
        }
        await MarketingAjax.savePage({
          actId: this.activityID,
          customModule: JSON.stringify(this.modules)
        });
        this.$message.success("保存成功");

        // await this.getPage();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 优惠券列表
    async getCouponList() {
      try {
        const data = await MarketingAjax.getCouponList({
          actId: this.activityID,
          brandId: "",
          name: "",
          page: 1,
          size: 1000
        });
        console.log(data);
        if (data.data.list && data.data.list.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 秒杀商品列表
    async getSeckillProductList() {
      try {
        const data = await MarketingAjax.getSeckillProductList({
          actId: this.activityID,
          brandId: "",
          name: "",
          page: 1,
          size: 1000
        });
        if (data.data.list && data.data.list.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 集赞设置-查看，传入活动id(尚未创建时返回null）
    async getCollectLike() {
      try {
        const data = await MarketingAjax.getCollectLike(this.activityID);
        console.log(data.data);
        if (data.data) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 公众号引流设置，传入活动id(尚未创建时返回null）
    async getPublicAccount() {
      try {
        const data = await MarketingAjax.getPublicAccount(this.activityID);
        if (data.data && data.data.wxMqName && data.data.wxMqQrcode) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.$message.error(error);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image"
      });
      this.uploadvideo = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "mp4",
        fileType: "video"
      });
    });
  },
  created() {
    this.getPage();
  },
  destroyed() {
    this.PreHeatVideo.dispose();
  }
};
</script>

<style scoped>
.videoactive{
  visibility: hidden;
}
.top-subscribe-tips {
  background: linear-gradient(-26deg, #ff3000, #ff7a00);
}
.top-subscribe-tips .subscribe-btn {
  width: 50px;
  height: 20px;
  line-height: 20px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff7eb),
    to(#ffdfb4)
  );
  background: linear-gradient(180deg, #fff7eb, #ffdfb4);
  border-radius: 10px;
  color: #ff3000;
}
.header-area .header-image {
  height: 375px;
  -o-object-fit: cover;
  object-fit: cover;
}
.header-area .live-enter {
  width: 30px;
  height: 70px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 3px 3px 0px;
  top: 30px;
  padding: 7px 8px;
  line-height: 14px;
}
.header-area .customer-service-enter {
  width: 30px;
  /*height: 44px;*/
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 3px 3px 0px;
  top: 110px;
  padding: 7px 8px;
  line-height: 14px;
}
.header-area .location-service {
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  top: 15px;
  right: 15px;
}
.header-area .somebody-join {
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  top: 65px;
  right: 15px;
  border-radius: 4px;
}
.header-area .somebody-join > i:first-child {
  color: #ffba7d;
}
.header-area .timing-area {
  width: 326px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  bottom: 108px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header-area .timing-area .getting-order {
  width: 105px;
  height: 33px;
  background: linear-gradient(-26deg, #ff3000, #ff7a00);
  border-radius: 2px;
}
.header-area .timing-area .count-down {
  height: 33px;
  line-height: 33px;
  border: 1px solid rgba(245, 246, 247, 0.2);
  border-radius: 2px;
  margin-right: 5px;
}
.header-area .sign-up {
  width: 170px;
  line-height: 44px;
  height: 44px;
  background: linear-gradient(-26deg, #ff3000, #ff7a00);
  border-radius: 22px;
  bottom: 43px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.customs-data {
  background-color: #f5f6f7;
}
.customs-data .custom-img > img {
  height: 2.11rem;
  -o-object-fit: cover;
  object-fit: cover;
}
.preheat-data {
  width: 345px;
  background: white;
  border-radius: 5px;
  margin-top: -20px;
  padding: 25px 0;
}
.coupon-cover {
  border-radius: 8px;
}
.coupon-cover > img {
  border-radius: 8px 8px 0 0;
}
.coupon-cover .time-item-ml {
  background-color: #ff0000;
  padding: 2px 5px;
}
.coupon-item-mine {
  border-radius: 5px;
}
.coupon-item-pv .coupon-item-mine {
  width: 95%;
}
.coupon-item-pv .el-icon-delete {
  display: none;
}
.coupon-item-pv:hover .el-icon-delete {
  display: inline-block;
}
.coupon-item-mine .couponname {
  width: 250px;
}
.coupon-item-mine .img-coupon-cover {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100px;
  height: 56px;
  border-radius: 2px;
}
.coupon-item-mine .coupon-item-get-btn {
  width: 65px;
  height: 25px;
  background: #f40000;
  border-radius: 13px;
  line-height: 25px;
  text-align: center;
  color: #fff;
}
.coupon-item-mine .coupon-til-tip .cblue {
  background-color: rgba(0, 132, 255, 0.1);
  padding: 3px 5px;
}

/* modules start */
.modules-item {
  border: 1px dashed #0084ff;
  border-radius: 4px;
  position: relative;
  min-height: 100px;
  margin-bottom: 10px;
}
.modules-item[type="0"] {
  border-color: #0084ff;
}
.modules-item[type="1"] {
  border-color: #ff3b3b;
}
.modules-item[type="2"] {
  border-color: #ff9800;
}
.modules-item[type="3"] {
  border-color: #01b542;
}
.modules-item[type="5"] {
  border-color: #02c7ce;
}
.modules-item.act {
  border-width: 2px;
  border-style: solid;
}
.modules-item img {
  width: 100%;
  height: 198px;
  object-fit: cover;
}

.mul-item-i.w100p img {
  height: auto;
}

.modules-item .video-mul {
  height: 180px;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-size: 100% 150%;
  background-position-y: 50%;
}
.modules-item .time-item-ml {
  color: #fff;
  background-color: #ff0000;
  padding: 2px 5px;
  border-radius: 4px;
}
.coupon-header img {
  border-radius: 5px 5px 0 0;
}
.modules-item .coupon-ml img {
  object-fit: cover;
}
.coupon-item-pv {
  width: 325px;
  margin-right: 20px;
}

.my-image-list-model {
  width: 220px;
}
.edit-item-image {
  width: 220px;
  object-fit: cover;
}
.play-icon-ml {
  width: 80px !important;
  height: 80px !important;
}
.mul-item-i .el-icon-delete {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0;
  top: 5px;
  right: 5px;
  z-index: 1;
  transition: display 0.2ms ease;
}
.mul-item-i:hover .el-icon-delete {
  display: unset;
}

.mul-item-i .coupon-item-mine::before,
.mul-item-i .coupon-item-mine::after {
  content: "";
  width: 20px;
  height: 18px;
  background-color: #eeeeee;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: 93px;
}
.mul-item-i .coupon-item-mine::after {
  top: unset;
  bottom: 0;
}
.auto-tips {
  line-height: 22px;
}
.set-options .el-radio-button__inner {
  padding: 12px 14px;
}
.remove-modules {
  top: 0;
  right: 0px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  color: #fff;
  border-radius: 0 5px;
  display: none;
}
.modules-item:hover .remove-modules {
  display: inline-block;
}
.preview-left,
.phone-previvew {
  width: 375px;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.phone-body,
.set-preview {
  height: 600px;
  overflow-y: scroll;
  padding: 5px;
  box-sizing: border-box;
}
/* 优惠券 start */
.coupon-item-mine .img-coupon-cover {
  width: 100px;
  height: 56px;
  object-fit: cover;
}
.coupon-item-mine .coupon-til-tip .cblue {
  background-color: rgba(0, 132, 255, 0.1);
  padding: 3px 5px;
}
.coupon-item-mine .coupon-description {
  width: 245px;
}
.coupon-item-pv .coupon-description {
  width: 300px;
}
.coupon-item-get-btn {
  width: 65px;
  height: 25px;
  background: rgba(244, 0, 0, 1);
  border-radius: 13px;
  line-height: 25px;
  text-align: center;
  color: #fff;
}
/* 优惠券 end */
.video-js .vjs-tech,
.preview-video-dimensions {
  width: 100% !important;
  height: 100% !important;
}
.edite-item-image i.el-icon-delete {
  border-radius: 0 0 8px 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.seckill-item i {
  display: inline-block;
  width: 25px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  background: rgba(244, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #f40000;
  margin-left: 5px;
}

.preview-goods .imgurl {
  width: 100px;
  height: 100px !important;
}
.preview-goods .brand-logo {
  width: 59px;
  height: 29px;
}
.seckill-btn {
  width: 80px;
  height: 30px;
  border: 1px solid rgba(244, 0, 0, 1);
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: rgba(244, 0, 0, 1);
  line-height: 30px;
}
.seckill-time {
  border-bottom: 1px solid #f5f6f7;
}
</style>
<style>
.input-p input {
  padding-right: 50px;
  padding-left: 5px;
}
</style>
