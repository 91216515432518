
<template>
  <div class="shop-wrapper disFlex">
    <div>
      <div class="phone-previvew mr20 bor1d pr br4">
        <img style="width: 375px; height: 560px" :src="skinImg" />
      </div>
    </div>
    <div class="setting-box">
      <div class="table-title">
        <el-alert
          title="温馨提示：切换页面风格后，自定义导航图标将会失效并恢复为初始默认图标，可进行重新上传"
          type="warning"
          :closable="false"
        ></el-alert>
        <div class="skin-list disFlex">
          <div
            class="blue-skin skinitem"
            @click="
              Default = 'blue';
              skinImg = blueSkin;
            "
          >
            <i
              class="el-icon-circle-check"
              style="color: #fff; font-size: 40px !important"
              v-if="Default == 'blue'"
            ></i>
          </div>
          <div
            class="red-skin skinitem"
            style="margin-left: 60px"
            @click="
              Default = 'red';
              skinImg = redSkin;
            "
          >
            <i
              class="el-icon-circle-check"
              style="color: #fff; font-size: 40px !important"
              v-if="Default == 'red'"
            ></i>
          </div>
          <div
            class="purple-skin skinitem"
            style="margin-left: 60px"
            @click="
              Default = 'purple';
              skinImg = purpleSkin;
            "
          >
            <i
              class="el-icon-circle-check"
              style="color: #fff; font-size: 40px !important"
              v-if="Default == 'purple'"
            ></i>
          </div>
          <div
            class="green-skin skinitem"
            style="margin-left: 60px"
            @click="
              Default = 'green';
              skinImg = greenSkin;
            "
          >
            <i
              class="el-icon-circle-check"
              style="color: #fff; font-size: 40px !important"
              v-if="Default == 'green'"
            ></i>
          </div>
          <!-- <div
            class="yellow-skin skinitem"
            style="margin-left: 60px"
            @click="
              Default = 'yellow';
              skinImg = yellowSkin;
            "
          >
            <i
              class="el-icon-circle-check"
              style="color: #fff; font-size: 40px !important"
              v-if="Default == 'yellow'"
            ></i>
          </div> -->
        </div>
        <el-button
          type="primary"
          class="confirmbutton"
          style="width: 300px"
          @click="saveStyle"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "ShopSetting", // 店铺配置
  components: {},
  props: {
    activityID: String,
  },
  computed: {},
  watch: {},
  data() {
    return {
      upload: null, // 上传对象
      baseInfo: {
        id: "",
        actName: "", // 活动名称
        timeRange: [],
        actStartTime: "", // 活动开始时间
        actEndTime: "", // 活动结束时间
        // 活动区域列表
        areaList: [
          {
            city: "",
            county: "",
            province: "",
          },
        ],
        poster: "", // 活动海报图url
        complainMobile: "", // 投诉电话
        liveStartTime: "", // 直播开始时间
        liveUrl: "", // 直播地址
      },
      storeInfo: {
        actId: this.activityID,
        logo: "",
        notice: "",
        showType: "all",
      },
      radio: "",
      Default: "blue",
      redSkin:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/red/skin.png",
      blueSkin:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/blue/skin.png",
      purpleSkin:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/purple/skin.png",
      greenSkin:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/green/skin.png",
      yellowSkin:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/yellow/skin.png",
      skinImg:
        "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/image/common/style/blue/skin.png",
    };
  },
  methods: {
    // 店铺设置/进店攻略-查看，传入活动id。
    async getStyle() {
      try {
        const data = await MarketingAjax.getStyle(this.activityID);
        if (data.data) {
          console.log(data);
          this.Default = data.data.style;
          switch (this.Default) {
            case "red":
              this.skinImg = this.redSkin;
              break;
            case "blue":
              this.skinImg = this.blueSkin;
              break;
            case "purple":
              this.skinImg = this.purpleSkin;
              break;
            case "green":
              this.skinImg = this.greenSkin;
              break;
            case "yellow":
              this.skinImg = this.yellowSkin;
              break;
            default:
              break;
          }
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑店铺设置
    async saveStyle() {
      try {
        let act = {
          actId: this.activityID,
          style: this.Default,
        };
        await MarketingAjax.saveStyle(act);
        this.$message.success("编辑页面风格成功");
        this.getStyle();
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getStyle();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.shop-wrapper {
  .table-title {
    font-weight: 600;
    font-size: 18px;
    margin: 20px;
  }
  .skin-list {
    margin: 20px;
  }
  .skinitem {
    border-radius: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .red-skin {
    background-color: red;
  }
  .blue-skin {
    background-color: #003078;
  }
  .purple-skin {
    background-color: #3b13a7;
  }
  .green-skin {
    background-color: #14413a;
  }
  .yellow-skin {
    background-color: #f5bb4f;
  }
  .chosentitle {
    margin-top: 5px;
  }
  .chosen {
    border: 3px solid #0148bb !important;
  }
  .hiddentitle {
    visibility: hidden;
  }
  .confirmbutton {
    margin: 40px 20px;
  }

  .preview-left,
  .phone-previvew {
    width: 375px;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .phone-body,
  .set-preview {
    height: 600px;
    padding: 5px;
    box-sizing: border-box;
  }
  .setting-box {
    margin-left: 40px;
    background: #fff;
    flex: 1;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>