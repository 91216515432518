var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "scratchmodel"
    },
    [
      _c("el-alert", {
        staticClass: "mb10",
        attrs: {
          title:
            "用户报名成功后，可参与刮奖活动，用奖品激励的方式可以吸引更多人报名哦。",
          type: "info",
          closable: false
        }
      }),
      _c(
        "el-form",
        {
          ref: "scratchform",
          attrs: { model: _vm.form, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                rules: _vm.validActivityendTime,
                prop: "Activityendtime",
                label: "活动截止时间:"
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.form.Activityendtime,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "Activityendtime", $$v)
                  },
                  expression: "form.Activityendtime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "disflex flexdr" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    rules: _vm.validRedeemStartTime,
                    prop: "Redeemstarttime",
                    label: "兑奖时间:"
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "开始时间",
                      "picker-options": _vm.redeemStartDatePicker,
                      disabled: this.disabledRedeemstarttime
                    },
                    model: {
                      value: _vm.form.Redeemstarttime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "Redeemstarttime", $$v)
                      },
                      expression: "form.Redeemstarttime"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticClass: "pl10 pr10 h35 lh35 inlineb" }, [
                _vm._v("-")
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "0",
                    rules: _vm.validRedeemEndTime,
                    prop: "Redeemendtime"
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      rules: _vm.validRedeemEndTime,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "结束时间",
                      "picker-options": _vm.redeemEndDatePicker
                    },
                    model: {
                      value: _vm.form.Redeemendtime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "Redeemendtime", $$v)
                      },
                      expression: "form.Redeemendtime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "限制中奖区域:" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.form.Id > 0 },
                  model: {
                    value: _vm.form.Restrictapy,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "Restrictapy", $$v)
                    },
                    expression: "form.Restrictapy"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("无限制")]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("服务区域内用户方可中奖")
                  ])
                ],
                1
              ),
              _c("VDistpicker", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.Restrictapy == 1,
                    expression: "form.Restrictapy == 1"
                  }
                ],
                attrs: {
                  disabled: _vm.form.Id > 0,
                  "hide-area": "",
                  province: _vm.form.RestricProvince,
                  city: _vm.form.RestricCity,
                  placeholders: { province: "", city: "" }
                },
                on: { selected: _vm.bmylselect },
                model: {
                  value: _vm.form.Restric,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "Restric", $$v)
                  },
                  expression: "form.Restric"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动简介:" } },
            [
              _c("el-input", {
                staticStyle: { width: "430px" },
                attrs: {
                  type: "text",
                  maxlength: "20",
                  "show-word-limit": "",
                  placeholder: "可获得最高5万元家居礼包 或 免费奖品哦！"
                },
                model: {
                  value: _vm.form.Name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "Name", $$v)
                  },
                  expression: "form.Name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动规则:" } },
            [
              _c("el-input", {
                staticStyle: { width: "430px" },
                attrs: {
                  type: "textarea",
                  rows: 5,
                  maxlength: "800",
                  placeholder:
                    "建议填写活动的具体规则，如：中奖用户需到指定门店进行奖品兑换..."
                },
                model: {
                  value: _vm.form.Detail,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "Detail", $$v)
                  },
                  expression: "form.Detail"
                }
              }),
              _c("p", { staticClass: "fz12 cae" }, [
                _vm.form.Detail
                  ? _c("i", { staticClass: "cred" }, [
                      _vm._v(_vm._s(_vm.form.Detail.length))
                    ])
                  : _vm._e(),
                _vm._v("/800")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "添加奖品:" } },
            [
              _c(
                "p",
                {
                  staticClass: "p10 pl0 cae fz14",
                  staticStyle: { "line-height": "22px" }
                },
                [
                  _vm._v(
                    "奖品池最大中奖率为100%，每款奖品所设置的中奖率会占奖品池一定的百分比例，单款奖品可用库存不足时，会减去对应的中奖率百分比，当所有奖品中奖率总和达到100%时，将无法再添加奖品。活动开始后，奖品不可删除，请谨慎设置。"
                  )
                ]
              ),
              _c("div", { staticClass: "p10 pl0" }, [
                _c(
                  "div",
                  { staticClass: "pr inlineb" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled:
                            _vm.form.ComplimentaryRelationList.length >= 20,
                          type: "primary",
                          icon: "el-icon-plus",
                          plain: ""
                        },
                        on: { click: _vm.openGiftList }
                      },
                      [_vm._v("添加奖品")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "el-tooltip__popper is-light",
                        staticStyle: {
                          position: "absolute",
                          top: "2px",
                          left: "121px",
                          "transform-origin": "left center",
                          width: "220px"
                        },
                        attrs: {
                          "aria-hidden": "false",
                          "x-placement": "right"
                        }
                      },
                      [
                        _vm._v(
                          " 现已添加" +
                            _vm._s(_vm.form.ComplimentaryRelationList.length) +
                            "款奖品，最多可添加20款。 "
                        ),
                        _c("div", {
                          staticClass: "popper__arrow",
                          staticStyle: { top: "8px" }
                        })
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.form.ComplimentaryRelationList
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "奖品", prop: "Name" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "已用/可用" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _c("span", {}, [
                            _vm._v(
                              _vm._s(scope.row.SalesVolume) +
                                "/" +
                                _vm._s(scope.row.LockStock)
                            )
                          ])
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "中奖概率" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(scope.row.WinningRatio) + "%")
                          ])
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _c(
                            "div",
                            { staticClass: "diflex flexdr" },
                            [
                              _c(
                                "el-tag",
                                {
                                  staticClass: "cp",
                                  on: {
                                    click: function($event) {
                                      return _vm.editeChooseGift(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-tag",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.relationId == null,
                                      expression: "scope.row.relationId==null"
                                    }
                                  ],
                                  staticClass: "ml10 cp",
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteChooseGift(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "p10 pl0 cae fz14",
                  staticStyle: { "line-height": "22px" }
                },
                [
                  _c("i", { staticClass: "fz14 el-icon-medal-1 mr5" }),
                  _vm._v("你当前所配置的奖品池总中奖率为 "),
                  _c("i", { staticClass: "cred" }, [
                    _vm._v(_vm._s(_vm.totalRate) + "%")
                  ]),
                  _vm._v(" ，用户不中奖的概率为 "),
                  _c("i", { staticClass: "cred" }, [
                    _vm._v(_vm._s(_vm.hasWinRate) + "%")
                  ]),
                  _vm._v("。")
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.form.id == 0 ? "立即创建" : "保存编辑"))]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.remove } },
                [_vm._v("删除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加奖品",
            visible: _vm.showGitfList,
            closeOnClickModal: false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showGitfList = $event
            }
          }
        },
        [
          _c("el-alert", { attrs: { type: "warning", closable: false } }, [
            _c("p", [
              _vm._v("奖品来自于奖品池，更多奖品可再奖品池进行添加。"),
              _c(
                "a",
                {
                  staticClass: "cblue ml10",
                  attrs: {
                    href:
                      "/admin?zbid=" +
                      _vm.zbid +
                      "#/homelive/complimentaryList",
                    target: "_block"
                  }
                },
                [_vm._v("去奖品池>>")]
              )
            ])
          ]),
          _c(
            "el-steps",
            {
              staticClass: "mauto mt10 mb10",
              staticStyle: { width: "400px" },
              attrs: { active: _vm.active }
            },
            [
              _c("el-step", { attrs: { title: "1 选择奖品" } }),
              _c("el-step", { attrs: { title: "2 设置奖品" } })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active == 1,
                  expression: "active == 1"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "p10 pl0 boxS" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-right": "20px"
                            },
                            attrs: {
                              size: "small",
                              placeholder: "请输入奖品id"
                            },
                            model: {
                              value: _vm.giftId,
                              callback: function($$v) {
                                _vm.giftId = $$v
                              },
                              expression: "giftId"
                            }
                          }),
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-right": "20px"
                            },
                            attrs: {
                              size: "small",
                              placeholder: "请输入奖品名称"
                            },
                            model: {
                              value: _vm.giftName,
                              callback: function($$v) {
                                _vm.giftName = $$v
                              },
                              expression: "giftName"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "medium"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getComplimentaryList(1)
                                }
                              }
                            },
                            [_vm._v("搜索")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                plain: "",
                                icon: "el-icon-refresh",
                                size: "medium"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getComplimentaryList(1)
                                }
                              }
                            },
                            [_vm._v("刷新")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  attrs: {
                    "highlight-current-row": true,
                    height: _vm.listdata.length ? 400 : 100,
                    data: _vm.listdata,
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "奖品ID", prop: "id", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品名称",
                      prop: "Name",
                      "min-width": "120",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品",
                      "min-width": "120",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _c("span", {}, [
                            _c("img", {
                              staticStyle: { width: "48px", height: "48px" },
                              attrs: { src: scope.row.ImgUrl }
                            })
                          ])
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "已用/剩余",
                      "min-width": "80",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.LockStock +
                                    "/" +
                                    (scope.row.stock - scope.row.LockStock)
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "80",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                staticClass: "cp",
                                on: {
                                  click: function($event) {
                                    return _vm.getMyGift(scope.row)
                                  }
                                }
                              },
                              [_vm._v("添加")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "dialog-footer disflex flexdr justifysb mt20 alignic"
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageindex,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      background: "",
                      total: _vm.pageTotal
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "disflex h40 lh40" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.listdata.length,
                              expression: "listdata.length"
                            }
                          ],
                          staticClass: "mr20"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "block mauto",
                              attrs: { type: "primary" },
                              on: { click: _vm.toAddGift }
                            },
                            [_vm._v("添加奖品")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showGitfList = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active == 2,
                  expression: "active == 2"
                }
              ],
              staticClass: "p20 boxS"
            },
            [
              _c(
                "el-form",
                {
                  ref: "formmodel",
                  attrs: { model: _vm.editeModel, "label-width": "90px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖品名称:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", value: _vm.editeModel.Name }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑换说明:" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          disabled: "",
                          value: _vm.editeModel.Detail
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        rules: _vm.giftNumber,
                        prop: "InitialStock",
                        label: "奖品数量:"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "fz14 disflex flexdr alignic" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text" },
                            model: {
                              value: _vm.editeModel.InitialStock,
                              callback: function($$v) {
                                _vm.$set(_vm.editeModel, "InitialStock", $$v)
                              },
                              expression: "editeModel.InitialStock"
                            }
                          }),
                          _c("span", { staticClass: "ml5" }, [_vm._v("份")])
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "pr10 boxS",
                          staticStyle: { "line-height": "22px" }
                        },
                        [
                          _vm._v(
                            "(件数区间值0~9999，奖品数量只能增加不能减少，请谨慎设置，奖品数量不可超过库存量，当前可用库存数为" +
                              _vm._s(_vm.editeModel.currentMaxStock) +
                              ")"
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "WinningRatio",
                        rules: _vm.winRateTRules,
                        label: "中奖率:"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "fz14 disflex flexdr alignic" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              type: "text",
                              disabled: _vm.editeModel.relationId != null
                            },
                            model: {
                              value: _vm.editeModel.WinningRatio,
                              callback: function($$v) {
                                _vm.$set(_vm.editeModel, "WinningRatio", $$v)
                              },
                              expression: "editeModel.WinningRatio"
                            }
                          }),
                          _c("span", { staticClass: "ml5" }, [_vm._v("%")])
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "pr10 boxS",
                          staticStyle: { "line-height": "22px" }
                        },
                        [
                          _vm._v(
                            "(中奖率区间值0.001%~100%，中奖率不可超过奖品池剩余可配置的中奖率" +
                              _vm._s(_vm.editeModel.hasWinRate) +
                              "%)"
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "disflex flexdr justifycc" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.pushGift } },
                    [_vm._v(_vm._s(_vm.editeIndex > 0 ? "保存编辑" : "添加"))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "parimy",
                        plain: "",
                        disabled:
                          _vm.form.ComplimentaryRelationList.length >= 20
                      },
                      on: {
                        click: function($event) {
                          _vm.active = 1
                        }
                      }
                    },
                    [_vm._v("重新选择")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }