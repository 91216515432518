<template>
  <div class="vz-material-wrap">
    <p
      style="margin-bottom:10px;color:#aeaeae;font-size: 14px;display: flex;justify-content: space-between;"
    >
      <el-checkbox
        v-if="false"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选（{{ checkList.length }}）{{
          max ? "最多" + max + "个" : ""
        }}</el-checkbox
      >
      <span>已选({{ checkList.length }})个/可选{{ max ? max : 1 }}个</span>
      <span>{{ list.length }}/{{ total }}</span>
    </p>
    <div
      class="vz-material-list"
      @scroll="loadmore"
      ref="list"
      style="max-height: 400px;overflow-y: scroll;margin-bottom: 10px;"
    >
      <el-checkbox-group
        v-model="checkList"
        @change="handleCheckItem"
        style="display: flex;flex-flow: wrap;"
      >
        <div v-for="(v, i) in list" :key="v.Id" class="vzm-material-item">
          <el-checkbox :label="i">
            <img
              v-if="filetype == 3"
              :src="v.src | formatImage"
              class="vzm-material"
            />
            <img
              v-else-if="filetype == 1"
              src="http://j.weizan.cn/zhibo/img/play.png"
              style="background-color: rgba(0,0,0,0.5)"
              class="vzm-image"
            />
            <p>{{ v.FileName }}</p>
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <p style="text-align: center;margin-bottom:10px;">
        {{ hasMore ? (isfetching ? "加载中" : "下拉加载更多") : "已加载全部" }}
      </p>
    </div>
    <div style="display: flex;justify-content: center;">
      <el-button @click="close">取消</el-button>
      <el-button @click="complete" type="primary">完成</el-button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { clone } from "@/utils/utils";
export default {
  name: "vz-material",
  props: {
    zid: {
      type: [Number, String],
      default: 0
    },
    // 1 视频 2 图片
    filetype: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    // 是否需要默认素材
    headimg: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    this.MsgIndex_VM = 0;
    return {
      isIndeterminate: false,
      pageIndex: 1,
      pageSize: 18,
      hasMore: true,
      total: 0,
      list: [],
      checkList: [],
      isfetching: false,
      checkAll: false,
      headimglist: [
        {
          id: 4567821,
          src:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/head-images-1.png"
        },
        {
          id: 4567822,
          src:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/head-images-2.png"
        },
        {
          id: 4567823,
          src:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/head-images-3.png"
        },
        {
          id: 4567824,
          src:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/head-images-4.png"
        },
        {
          id: 4567825,
          src:
            "https://j.weizan.cn/zhibo/livecontent/homelive/img/head-images-5.png"
        }
      ]
    };
  },
  filters: {
    formatImage: function(url) {
      return (
        url.split("?")[0].replace("i2.vzan.cc", "i2cut.vzan.cc") +
        "?imageMogr2/auto-orient/thumbnail/600x"
      );
    }
  },
  watch: {
    filetype: function() {
      this.initParams();
      this.$nextTick(this.getlist);
    }
  },
  methods: {
    initParams: function() {
      this.pageIndex = 1;
      this.list = [];
      this.checkList = [];
      this.checkAll = false;
      this.total = 0;
      this.hasMore = true;
    },
    // 完成
    complete: function() {
      var that = this;
      var list = [];
      this.checkList.forEach(function(el) {
        if (that.list[el]) list.push(clone(that.list[el]));
      });
      this.checkList = [];
      this.checkAll = false;
      this.$emit("complete", list);
    },
    // 单选控制
    handleCheckItem: function() {
      if (this.checkList.length > this.max) {
        this.checkList = this.checkList.slice(0, this.max);
        this.MsgIndex_VM && this.MsgIndex_VM.close();
        this.MsgIndex_VM = this.$message.info("单次最多选择" + this.max + "个");
      }
    },
    // 全选
    handleCheckAllChange: function(val) {
      if (val) {
        this.checkList = this.list.map(function(el, i) {
          return i;
        });
        if (this.checkList.length > this.max) {
          this.checkList = this.checkList.slice(0, this.max);
        }
      } else {
        this.checkList = [];
      }
      this.isIndeterminate = false;
    },
    // 下拉加载
    loadmore: function() {
      var sdom = this.$refs.list;
      var h = Math.ceil(sdom.scrollHeight);
      var sh = Math.ceil(sdom.scrollTop + sdom.clientHeight);
      if (h === sh) {
        this.getlist();
      }
    },
    // 关闭
    close: function() {
      this.checkList = [];
      this.checkAll = false;
      this.$emit("close");
    },
    // 列表
    getlist: function() {
      if (!this.hasMore || this.isfetching) {
        return;
      }
      var that = this;
      this.isfetching = true;
      $.ajax({
        url: "/nlive/material?zid=" + this.zid,
        type: "POST",
        dataType: "JSON",
        data: {
          pageindex: this.pageIndex,
          psize: this.pageSize,
          zid: this.zid,
          searchType: "json",
          id: "",
          fileName: "",
          state: 1,
          fileType: this.filetype || 3,
          pageSize: 10
        },
        success: function(res) {
          if (res.isok) {
            if (res.dataobj.length) {
              that.hasMore = true;
              that.list = that.list.concat(
                res.dataobj.map(function(el) {
                  el.src =
                    el.FilePath !== ""
                      ? el.FilePath.replace("i2.vzan.cc", "i2cut.vzan.cc") +
                        "?imageMogr2/auto-orient"
                      : el.NetworkFilePath;
                  return el;
                })
              );

              that.total = that.headimg ? 5 + parseInt(res.code) : res.code;
              that.pageIndex += 1;
              if (res.dataobj.length < that.pageSize) {
                that.hasMore = false;
              }
              // 继续全选下一页
              if (that.checkAll && that.checkList.length < that.max) {
                that.$nextTick(function() {
                  that.handleCheckAllChange(true);
                });
              }
            } else {
              that.hasMore = false;
            }
          } else {
            that.hasMore = false;
          }
        },
        error: function (error) {
          that.$message.error(error);
        },
        complete: function() {
          that.isfetching = false;
        }
      });
    }
  },
  created: function() {
    if (this.headimg) {
      this.list = this.list.concat(this.headimglist);
    }
  },
  mounted: function() {
    this.getlist();
  }
};
</script>

<style >
.vzm-material-item .vzm-material {
    width: 132px;
    height: 100px;
    object-fit: cover;
}

.vzm-material-item .el-checkbox__input{
    position: absolute;
    top: 15px;
    left: 15px;
}
.vzm-material-item .el-checkbox__label {
    padding: 0;
    margin: 10px;
}
</style>
