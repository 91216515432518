<template>
  <div>
    <div class="disflex flexdr mb10 alignic">
      <el-input placeholder="请输入内容" style="width: 350px;" v-model="queryPara.title" class="input-with-select">
        <el-button slot="append" @click="searchbrand" icon="el-icon-search"></el-button>
      </el-input>
      <div :class="{ml10:queryPara.title.length}">
        <el-button type="danger" @click="cancelSearch" v-show="queryPara.title.length" >撤销搜索</el-button>
      </div>
      <div class="pl10 disflex flexdr">
        <el-button icon="el-icon-refresh" size="medium" @click="cancelSearch">刷新</el-button>
        <a target="_blank" style="color:#0084ff;line-height:36px;margin-left: 10px;" :href="'/admin/?zbid='+zbid+'#/homelive/seckillModel/0'">新建秒杀活动</a>
      </div>
    </div>
    <div style="height: 350px;width:100%;overflow-y: scroll;">
      <el-checkbox-group v-if="multiple" style="width:100%" v-model="checkedAct">
        <el-table
          :data="activityList"
          border
          v-loading="loading"
          style="width:100%"
        >
          <el-table-column
            label="活动ID"
            width="80"
          >
            <div slot-scope="scope">
              <el-checkbox :label="scope.row.id" :key="scope.row.id">{{scope.row.id}}</el-checkbox>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            label="活动名称"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="活动时间"
          >
            <div slot-scope="scope">
              <p>开始时间：{{scope.row.startTime}}</p>
              <p>结束时间：{{scope.row.endTime}}</p>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            width="120"
            label="活动状态"
          >
            <span slot-scope="scope" >{{scope.row.timeStatus|activityState}}</span>
          </el-table-column>
        </el-table>
      </el-checkbox-group>
      <el-radio-group v-else style="width:100%" v-model="checkedAct">
        <el-table
          :data="activityList"
          border
          v-loading="loading"
          style="width:100%"
        >
          <el-table-column
            label="活动ID"
            width="80"
          >
            <div slot-scope="scope">
              <el-radio :label="scope.row.id" :key="scope.row.id">{{scope.row.id}}</el-radio>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            label="活动名称"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="活动时间"
          >
            <div slot-scope="scope">
              <p>开始时间：{{scope.row.startTime}}</p>
              <p>结束时间：{{scope.row.endTime}}</p>
            </div>
          </el-table-column>
          <el-table-column
            prop="title"
            label="活动状态"
            width="120"
          >
            <span slot-scope="scope" >{{scope.row.timeStatus|activityState}}</span>
          </el-table-column>
        </el-table>
      </el-radio-group>
    </div>
    <div class="disflex flexdr justifysb mt10">
      <!-- <el-button type="primary" plain size="small" v-if="multiple" @click="toggleAllRadio" style="width:100px;">{{checkedAct.length !== activityList.length ? '全选' : '取消全选'}}</el-button> -->
      <el-pagination
        layout="total, prev, pager, next,jumper"
        :total="totalNumber"
        @current-change="GetHomeSeckillActivitys"
      >
      </el-pagination>
    </div>
    <div class="disflex flexdr justifycc mt10">
      <el-button @click="cancelCheck">取消</el-button>
      <el-button @click="commitCheck" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
import { GetHomeSeckillActivitys } from '@/utils/https/HomeLive/preheat.js';
export default {
  props: {
    pageid: {
      type: [Number, String],
      default: 0
    },
    zbid: {
      type: [Number, String],
      default: 0
    },
    actid: {
      type: [Number, String],
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isshow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      queryPara: {
        pageindex: 1,
        pagesize: 10,
        title: "",
        status: 1
      },
      activityList: [],
      totalNumber: 0,
      checkedAct: 0,
      //缓存已选择的话题
      savedCheckedAct: [],
      loading: false
    };
  },
  filters: {
    formaturl (value) {
      const prefix = 'https://j.weizan.cn/images/vzanbg/';
      if (value.includes('http')) {
        return value;
      } else {
        return prefix + value;
      }
    },
    //0失效，1未开始，2进行中，3已经结束
    activityState (timeStatus) {
      return timeStatus == 0 ? '失效' : timeStatus == 1 ? '未开始' : timeStatus == 2 ? '进行中' : '已经结束';
    },
  },
  watch: {
    ids (value) {
      this.checkedAct = value;
    },
    actid (value) {
      this.checkedAct = value;
    },
    isshow (value) {
      if (value) {
        this.checkedAct = this.multiple ? this.ids : this.actid;
        this.GetHomeSeckillActivitys();
      }
    }
  },
  methods: {
    /**
     * 撤销搜索
     * @return void
     */
    cancelSearch () {
      this.checkedAct =  this.multiple ? [] : 0;
      this.queryPara.title = ''
      this.queryPara.pageindex = 1;
      this.GetHomeSeckillActivitys();
    },
    /**
     * 获取品牌列表
     * @return void
     */
    GetHomeSeckillActivitys (page = 1) {
      this.queryPara.pageindex = page;
      this.loading = true;
      GetHomeSeckillActivitys({
        zbid: this.zbid,
        ...this.queryPara
      }).then(res => {
        this.activityList = res.data;
        this.totalNumber = res.Amout;
      }).catch(msg => {
        this.$message.error(msg);
      }).finally(() => {
        this.loading = false;
      });
    },
    /**
     * 取消选择
     * @return void
     */
    cancelCheck () {
      // this.checkedAct =  this.multiple ? [] : 0;
      this.pageindex = 1;
      this.activityList = [];
      this.checkedAct =  this.multiple ? this.savedCheckedAct : 0;
      this.$emit('close', this.savedCheckedAct);
    },
    /**
     * 提交选择
     * @return void
     */
    commitCheck () {
      const value = (this.checkedAct).toString();
      if (value == 0 || value == '') {
        if (this.required) {
          this.$message.error('请选择你的话题')
          return;
        } else {
          this.$emit('multiple', this.multiple ? [] : 0);
          return;
        }
      } else {
        if (this.multiple) {
          this.savedCheckedAct = this.checkedAct
          this.$emit('complete', this.savedCheckedAct);
        } else {
          const item = this.activityList.find(el => el.id == this.checkedAct);
          this.$emit('complete', item);
        }
      }
      this.pageindex = 1;
      this.activityList = [];
      this.checkedAct = this.multiple ? this.savedCheckedAct : 0;
    },
    /**
     * 搜索品牌
     * @return void
     */
    searchbrand () {
      this.checkedAct =  this.multiple ? [] : 0;
      this.GetHomeSeckillActivitys(1);
    },
    /**
     * 全选
     * @return void
     */
    toggleAllRadio(){
      if(this.checkedAct.length === this.activityList.length){
        this.checkedAct = []
      }else{
        for(let item of this.activityList){
          this.checkedAct.push(item.id)
        }
      }
    }
  },
  mounted () {
    this.checkedAct = this.multiple ? this.ids : this.actid;
    this.GetHomeSeckillActivitys();
  }
}
</script>

<style lang="scss" scoped>

</style>