<template>
<div class="cardbaglist">
  <div class="disflex flexdr justifysb">
    <div class="">
      <el-button size="mini" class="block" type="primary" @click="$router.push('/homelive/cardbagmg')" icon="el-icon-setting" plain="" >礼包管理</el-button>
      <p class="fz12 mt5 cae">点击可去【营销-优惠券礼包】中新增、管理礼包</p>
    </div>
    <div class="disflex">
      <!-- 0未开启，1已经开启，-1删除，2已结束 -->
      <span>
        <el-button size="mini" @click="getCardBagList(1)" class="mr10" plain="" icon="el-icon-refresh">刷新</el-button>
      </span>
      <el-input size="mini" class="mr10" style="width: 300px;" v-model="queryData.Name" placeholder="请输入礼包名称"></el-input>
      <span>
        <el-button size="mini" @click="getCardBagList(1)" type="primary" icon="el-icon-search">搜索</el-button>
      </span>
      <span class="ml10" v-show="queryData.Name.length" >
        <el-button size="mini" @click="cancelSearch" type="danger" plain icon="el-icon-close">撤销搜索</el-button>
      </span>
    </div>
  </div>
  <el-radio-group v-model="myBagId" class="w100p">
    <el-table v-loading="loading" class="mt20" :data="cardBagData" border="">
      <el-table-column
        label="ID"
      >
        <el-radio :disabled="scope.row.Status !== 1 || scope.row.Status !== 1 || scope.row.Surplus <= 0" slot-scope="scope" :label="scope.row.Id"></el-radio>
      </el-table-column>
      <el-table-column
        prop="Name"
        label="礼包名称"
      >
      </el-table-column>
      <el-table-column
        prop="CouponNums"
        label="卡券类型(种)"
      >
      </el-table-column>
      <el-table-column
        prop="PriceStr"
        label="售价">
      </el-table-column>
      <el-table-column
        label="已放/剩余">
        <span slot-scope="scope">{{scope.row.Sales}}/{{scope.row.Surplus}}</span>
      </el-table-column>
      <el-table-column
        label="状态"
      >
        <div slot-scope="scope">
          <span>{{scope.row.StatusStr}}</span>
          <el-tag class="ml10 cp" @click="openState(scope.row.Id, scope.$index)" v-show="scope.row.Status == 0 || scope.row.Status == 2" type="success">开启</el-tag>
        </div>
      </el-table-column>
    </el-table>
  </el-radio-group>
  <el-pagination
    class="mt20"
    @current-change="getCardBagList"
    :current-page.sync="queryData.pageindex"
    :page-size="10"
    layout="total, prev, pager, next, jumper"
    :total="totalNumber">
  </el-pagination>
  <div class="mt20 disflex justifycc">
    <el-button type="primary" @click="done">完成选择</el-button>
    <el-button @click="$emit('cancel')">取消</el-button>
  </div>
</div>
</template>

<script>
import { GetCouponBags, HandleCouponBag } from '@/utils/https/HomeLive/cardbag.js';
export default {
  name: 'cardbaglist',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    myCardBag: Object,
  },
  model: {
    prop: 'myCardBag',
    event: 'upcardbag'
  },
  data () {
    return {
      queryData: {
        Status: '',
        Name: '',
        pagesize: 10,
        pageindex: 1,
        zbid: window.zbid
      },
      cardBagData: [],
      pageindex: 0,
      loading: false,
      totalNumber: 0,
      myBagId: 0,
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.myBagId = this.myCardBag.Id;
      }
    }
  },
  methods: {
    // 开启优惠券
    openState (couponBagId, i) {
      HandleCouponBag({
        zbid: window.zbid,
        couponBagId,
        status: 1
      })
        .then(() => {
          this.cardBagData[i].Status = 1;
          this.cardBagData[i].StatusStr = "已启用";
          this.$set(this.cardBagData, i, this.cardBagData[i]);
          this.$message.success("开启成功");
        })
        .catch(errorMsg => {
          if(errorMsg){
            this.$message.error(errorMsg);
          }else{
            this.$message.error("开启失败");
          }
        });
    },
    done () {
      const item = this.cardBagData.find(el => el.Id === this.myBagId) || { Id: 0, Status: 0, CouponList: [] };
      item.CouponList = [];
      this.$emit('upcardbag', item);
      this.$nextTick(() => {
        this.$emit('done');
      });
    },
    cancelSearch () {
      this.queryData.Name = '',
      this.Status = '';
      this.getCardBagList(1);
    },
    // 获取卡包列表
    async getCardBagList (page = 1) {
      if (this.loading) { return; }
      try {
        this.loading = true;
        this.queryData.pageindex = page;
        const res = await GetCouponBags(this.queryData);
        this.totalNumber = res.Amout;
        this.cardBagData = res.data || [];
        this.loading = false;
      } catch (error) {
        this.$message.error(error);
        this.loading = false;
      }
    }
  },
  mounted () {
    this.myBagId = this.myCardBag.Id;
    this.getCardBagList(1);
  }
}
</script>

<style lang="scss" scoped>

</style>