<template>
<div class="vz-material-wrap">
  <div style="margin-bottom: 15px;display: flex;justify-content: space-between;">
    <div style="display: flex;">
      <el-input style="width: 348px;" placeholder="优惠券名称" v-model="keyword" class="input-with-select">
        <el-button slot="append" @click="sreach" icon="el-icon-search"></el-button>
      </el-input>
      <el-button @click="cancelSreach" size="small" type="danger" v-show="keyword.length" style="margin-left: 10px;" plain>撤销搜索</el-button>
    </div>
    <div style="display: flex;">
      <span class="ml10 cp" style="color: #0084ff;line-height: 40px;" @click="refresh"  >刷新</span>
      <a class="ml10 cp" style="color: #0084ff;line-height: 40px;" target="_block" :href="`/NLive/OfflineCoupons?zid=${zid}`">管理核销优惠券</a>
      <a class="ml10 cp" style="color: #0084ff;line-height: 40px;" target="_block" :href="newCouponUrl">新建核销优惠券</a>
    </div>
  </div>
  <div style="margin-bottom:10px;color:#aeaeae;font-size: 14px;display: flex;justify-content: space-between;">
    <p v-show="page === 'preheat'" style="padding: 8px;width: 800px;line-height: 20px;" >* 以下列表展示【开始领取时间】小于【活动开始时间】的优惠券（需开启），如【活动开始时间】为 2020-5-18  00:00 ，即【优惠券开始时间】在2020-5-18 00:00 之前的优惠券均显示</p>
    <p v-show="page === 'cardbag'" style="padding: 8px;width: 600px;line-height: 20px;" >仅可添加状态为【进行中】的到店核销优惠券，优惠券礼包可对优惠券进行打包定价，不单独计算每张优惠券的售价，请谨慎选择。</p>
    <div style="display: flex;height: 30px;line-height: 30px;">
      <span>{{max?("最多"+max+"个"):""}}</span>
      <span style="margin-left: 10px;">已选择{{checkList.length}}张优惠券</span>
    </div>
 </div>
  <div class="vz-material-list" ref="list" style="max-height: 400px;overflow-y: scroll;margin-bottom: 10px;">
    <el-checkbox-group v-model="checkList" style="display: flex;flex-flow: wrap;">
      <el-table v-loading="isfetching" :data="list" border style="width: 100%">
        <el-table-column prop="CouponName" label="ID" width="110">
          <template slot-scope="scope"><el-checkbox :disabled="scope.row.Status != 2 || scope.row.isOverTime" @change="getItemCoupon(scope.row)" :label="scope.row.Id">{{scope.row.Id}}</el-checkbox></template>
        </el-table-column>
        <el-table-column prop="CouponName" label="优惠券名称" width="180"></el-table-column>
        <el-table-column  width="80px" prop="CouponMoney" label="优惠的金额">
          <template slot-scope="scope">{{(scope.row.CouponMoney*0.01).toFixed(2)}}</template></el-table-column>
        <el-table-column  width="80px" prop="payfee" label="支付的金额">
          <template slot-scope="scope">{{(scope.row.payfee*0.01).toFixed(2)}}</template></el-table-column>
        <el-table-column width="60px" prop="RenderNum" label="生成数量"></el-table-column>
        <el-table-column width="50px" prop="GetNum" label="领取数量"></el-table-column>
          <el-table-column width="50px" prop="UseNum" label="使用数量"></el-table-column>
          <el-table-column prop="BeginDate" label="开始领取时间"></el-table-column>
          <el-table-column prop="EndDate" label="结束领取时间"></el-table-column>
          <el-table-column prop="ValidDate" label="使用截止时间"></el-table-column>
        <el-table-column prop="OpenState"  width="80px" label="状态">
          <template slot-scope="scope">
            <span :class="scope.row.OpenState != 1 || scope.row.isOverTime ? 'cred':'cblue'">{{scope.row.State}}</span>
            <!-- <span>{{scope.row.isOverTime ? "已过期" : scope.row.OpenState==1 ? "进行中" : "未开启"}}</span> -->
          </template>
        </el-table-column>
        </el-table>
    </el-checkbox-group>
 </div>
  <el-pagination :page-size="pageSize" @current-change="handleCurrentChange" layout="total, prev, pager, next" :total="total"></el-pagination>
  <div style="display: flex;justify-content: center;">
    <el-button @click="complete" type="primary">完成选择</el-button>
    <el-button @click="close">取消</el-button>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { clone, formatTime } from "@/utils/utils";
export default{
  name: "vz-coupon",
  props: {
    zid: {
      type: [Number, String],
      default: 0
    },
    checkedlist: {
      type: Array,
      default: function () {
        return [];
      }
    },
    topicid: {
      type: Number,
      default: 0
    },
    ranges: {
      type: Array,
      default: function(){return []}
    },
    noranges: {
      type: Boolean,
      default: false
    },
    openState: {
      type: Number,
      default: 5
    },
    max: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    this.MsgIndex_VM_Coupon = 0;
    return {
      isIndeterminate: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      list: [],
      checkList: [],
      checkListItems: [],
      isfetching: false,
      checkAll: false,
      keyword: "",
      newCouponUrl: '',
      page: 'preheat'
    };
  },
  filters: {
    formatImage: function(url) {
      return url.replace("i2.vzan.cc", "i2cut.vzan.cc") + "?imageMogr2/auto-orient/thumbnail/600x";
    }
  },
  watch: {
    checkedlist: function (n) {
      this.checkList = clone(n);
    }
  },
  methods: {
    // 刷新
    refresh () {
      this.pageIndex = 1;
      this.keyword = '';
      this.getlist();
    },
    // 切换分页
    handleCurrentChange  (page) {
      this.pageIndex = page;
      this.getlist();
    },
    // 搜索
    sreach () {
      if (this.keyword.length) {
        this.initParams("search");
        this.getlist();
      } else {
        this.$message.info("请输入要搜索的内容！");
      }
    },
    // 撤销搜索
    cancelSreach () {
      this.initParams();
      this.getlist();
    },
    // 初始化参数
    initParams (type) {
      this.pageIndex = 1;
      this.list = [];
      this.checkList = [];
      this.checkListItems = [];
      this.checkAll = false;
      this.total = 0;
      if (type !== "search") {
        this.keyword = "";
      }
    },
    // 完成
    complete () {
      var list = clone(this.checkListItems);
      this.$emit("complete", list, this.checkList);
      this.checkList = [];
      this.checkListItems = [];
      this.checkAll = false;
    },
    // 单选控制 
    getItemCoupon  (i) {
      var ischecked = !$(event.target.labels[0]).hasClass('is-checked');
      if (ischecked) {
        if (this.checkList.length > this.max) {
          this.checkList = this.checkList.slice(0, this.max);
          this.checkListItems = this.checkListItems.slice(0, this.max);
          this.MsgIndex_VM_Coupon && this.MsgIndex_VM_Coupon.close();
          this.MsgIndex_VM_Coupon = this.$message.info("单次最多选择" + this.max + "张");
        } else {
          this.checkListItems.push(i);
        }
      } else {
        var cli = this.findi(this.checkList, function (el) {
          return el == i.Id;
        });
        if (cli >= 0) {
          this.checkList.splice(cli, 1);
        }
        // var cldi = this.findi(this.checkListItems, function (el) {
        //   return el.Id == i.Id;
        // });
        // if (cldi >= 0) {
        //   this.checkListItems.splice(cli, 1);
        // }
      }
    },
    findi  (arr, cb) {
      var index = -1;
      for (var ij = 0; ij < arr.length; ij++) {
        var el = arr[ij];
        var isok = cb(el);
        if (isok) {
          index = ij;
          break;
        }
      }
      return index;
    },
    // 关闭
    close () {
      this.checkList = [];
      this.checkListItems = [];
      this.checkAll = false;
      this.isfetching = false;
      this.list = [];
      this.$emit("close");
    },
    // 列表
    getlist () {
      if (this.isfetching || (this.ranges.length < 2 && !this.noranges)) {
        return;
      }
      var that = this;
      this.isfetching = true;
      $.ajax({
        url: "/nlive/OfflineCoupons",
        type: "POST",
        dataType: "JSON",
        data: {
          zid: this.zid,
          topicid: 0,// 不做话题绑定限制
          page: this.pageIndex,
          psize: this.pageSize,
          openState: this.openState,
          reqtype: 1,
          starttime: this.page === 'cardbagModel' ? '' : (typeof this.ranges == 'string' ? this.ranges[0] : formatTime(this.ranges[0], 1)),
          endtime: this.page === 'cardbagModel' ? '' : (typeof this.ranges == 'string' ? this.ranges[1] : formatTime(this.ranges[1], 1)),
          selectids: this.checkList.join(','),
          search: "page",
          couponName: this.keyword.length ? this.keyword : undefined,
          newCouponUrl: ''
        },
        success: function(res) {
          if (res.isok) {
            if (res.dataobj.length) {
              that.list = res.dataobj;
              that.total = parseInt(res.msg);
              that.pageIndex += 1;
            }
          }
        },
        error: function(error) {
          this.$message.error(error);
        },
        complete: function() {
          that.isfetching = false;
        }
      });
    }
  },
  mounted: function() {
    const routePath = this.$route.path;
    if (routePath.includes('cardbagModel')) {
      this.page = 'cardbag'
    } else if (routePath.includes('cardbagModel')) {
      this.page = 'preheat'
    }
    this.checkList = clone(this.checkedlist);
    this.newCouponUrl = "/nlive/AddCouponsNew?zid=" + this.zid + "&lineType=0";
    this.getlist();
  }
};
</script>

<style lang="scss" scoped>

</style>